// packages
import { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// components (template)
import Hero from "../../components/Hero";
import StandardContainer from "../../components/StandardContainer";

// data
import { hero, images, meta } from "../../data/pages/catalog";

export default function Catalog() {
  //////////////////////////////////////////////////////////////////////////////
  // lightbox
  //////////////////////////////////////////////////////////////////////////////
  const [index, setIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  const slides = images.map((item) => {
    return { src: item.image };
  });

  const openLightbox = (clickedIndex) => {
    setIndex(clickedIndex);
    setOpen(true);
  };

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const content = (
    <div className="mb-2 grid grid-cols-1 gap-2 sm:grid-cols-2 md:mb-8 md:grid-cols-3 md:gap-8">
      {images.map((item, index) => {
        return (
          <div
            className="overflow-hidden"
            key={index}
          >
            <img
              alt=""
              className="cursor-pointer transition-transform duration-500 hover:scale-125"
              loading="lazy"
              onClick={() => openLightbox(index)}
              src={item.image}
              width={1000}
              height={1250}
            />
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      <Hero
        title={meta.title}
        image={hero.image}
        content={hero.contents}
      />
      <StandardContainer content={content} />
      <Lightbox
        close={() => {
          setIndex(-1);
          setOpen(false);
        }}
        controller={{ closeOnBackdropClick: true }}
        index={index}
        open={open}
        slides={slides}
      />
    </>
  );
}
