// assets
import catalogImage1 from "../../assets/images/catalog/1-Garvani_Catalog_2023_03.avif";
import catalogImage2 from "../../assets/images/catalog/11-Garvani_Catalog_2023_23.avif";

export const features = [
  {
    image: { src: catalogImage1, alt: "", width: 1000, height: 1250 },
    content:
      "We welcome you to share our passion for exquisite design and craftsmanship of fine diamond jewelry. When celebrating life's most cherished moments, expression and emotion are one. Each item in our collection is considered a masterpiece, as excruciating detail and meticulous judgment determine its beauty. Our meticulous attention to detail is the cornerstone of our elegant, sophisticated styles.",
  },
  {
    image: { src: catalogImage2, alt: "", width: 1000, height: 1250 },
    content:
      "As a designer, manufacturer, and diamond cutter, we have the unique ability to incorporate various diamonds into our designs and tailor them to your specific preferences. With our in-house design department, we are dedicated to bringing your vision to life, expressing our commitment to value, quality, and beauty. Our masterpieces are available in upscale retailers throughout the United States and worldwide. ",
  },
];

export const descriptions = {
  diamonds:
    "Uncover the unmatched brilliance of our diamonds, carefully sourced from the finest origins and expertly cut to perfection. Each diamond in our exclusive collection is meticulously chosen for its exceptional clarity, color, and carat weight, ensuring the highest quality for the most discerning clientele. With precision craftsmanship, our diamonds are cut to maximize their fire and scintillation, offering unparalleled radiance and elegance. Whether for an engagement, investment, or bespoke piece, our loose diamonds provide the foundation for timeless beauty and extraordinary luxury. Embrace the ultimate expression of sophistication with a diamond that mirrors your unique style and vision.",
  jewelry:
    "Discover the exquisite artistry of our fine jewelry collection, where every piece is a testament to timeless elegance and masterful craftsmanship. Meticulously designed with the finest materials, each creation is thoughtfully crafted to embody luxury and sophistication. From radiant gemstones to intricate metalwork, our jewelry is selected for its superior quality, ensuring that every item reflects the beauty and refinement our discerning clients expect. Whether you're searching for a statement piece, an heirloom treasure, or a personalized design, our collection offers the perfect balance of style and grace. Embrace the extraordinary with jewelry that captures the essence of your unique taste and vision.",
};
