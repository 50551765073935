// packages
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import {
  LiaSortDownSolid,
  LiaSortSolid,
  LiaSortUpSolid,
} from "react-icons/lia";
import { useLocation } from "react-router-dom";

// components
import LoadingIndicator from "../components/LoadingIndicator";

// contexts
const SearchContext = createContext();
export const useSearch = () => {
  return useContext(SearchContext);
};

export const SearchProvider = ({
  children,
  searchType,
  params,
  requestUrl,
  formatData,
  routeProperties,
  routeShow,
  expandAdvancedSearch,
}) => {
  //////////////////////////////////////////////////////////////////////////////
  // data
  //////////////////////////////////////////////////////////////////////////////

  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  //////////////////////////////////////////////////////////////////////////////
  // pagination
  //////////////////////////////////////////////////////////////////////////////

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  //////////////////////////////////////////////////////////////////////////////
  // reset
  //////////////////////////////////////////////////////////////////////////////

  const [resetRequested, setResetRequested] = useState(false);

  useEffect(() => {
    if (resetRequested) {
      handleSearch();
      setResetRequested(false);
    }
  }, [resetRequested]);

  //////////////////////////////////////////////////////////////////////////////
  // search
  //////////////////////////////////////////////////////////////////////////////

  const [searchParams, setSearchParams] = useState(params);
  const [searchProperties, setSearchProperties] = useState({});
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const category = urlParams.get("category");
    if (category) {
      setSearchParams((prevParams) => ({
        ...prevParams,
        category: [category],
      }));
      setCurrentPage(1);
    }
  }, [location.search]);

  const handleReset = () => {
    setCurrentPage(1);
    setSearchParams(params);
    setResetRequested(true);
  };

  useEffect(() => {
    handleSearch();
  }, [currentPage, searchParams]);

  const fetchSearchProperties = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/companies/${process.env.REACT_APP_COMPANY_ID}/${routeProperties}.json`,
        {
          headers: {
            Authorization: localStorage.token,
          },
        },
      )
      .then((response) => {
        setSearchProperties(response.data);
      })
      .catch((error) => {
        console.error("Error fetching search properties:", error);
      });
  };

  const handleSearch = () => {
    setLoading(true);

    axios
      .get(requestUrl(searchParams, currentPage), {
        headers: {
          Authorization: localStorage.token,
        },
      })
      .then((response) => {
        setResults(formatData(response.data.data));
        setTotalPages(response.data.meta.page_count);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  //////////////////////////////////////////////////////////////////////////////
  // sorting
  //////////////////////////////////////////////////////////////////////////////

  const [sort, setSort] = useState({ key: "", direction: "asc" });

  const handleSort = (key) => {
    const direction =
      searchParams.sortBy === key && searchParams.sortDir === "asc"
        ? "desc"
        : "asc";

    setSort({ key: key, direction: direction });

    setSearchParams((prevParams) => ({
      ...prevParams,
      sortBy: key,
      sortDir: direction,
    }));
  };

  const getSortIcon = (key) => {
    if (!sort || key !== sort.key) {
      return <LiaSortSolid />;
    }

    if (sort.direction === "asc") {
      return <LiaSortUpSolid />;
    } else {
      return <LiaSortDownSolid />;
    }
  };

  //////////////////////////////////////////////////////////////////////////////
  // context
  //////////////////////////////////////////////////////////////////////////////

  return (
    <SearchContext.Provider
      value={{
        // data
        results,

        // search
        searchParams,
        setSearchParams,
        searchProperties,
        setSearchProperties,
        handleSearch,
        handleInputChange,
        fetchSearchProperties,
        expandAdvancedSearch,

        // reset
        handleReset,

        // sort
        getSortIcon,
        handleSort,

        // pagination
        currentPage,
        setCurrentPage,
        totalPages,
        handlePageChange,

        // routes
        routeShow,
      }}
    >
      {loading ? (
        <LoadingIndicator message={`Loading ${searchType}`} />
      ) : (
        children
      )}
    </SearchContext.Provider>
  );
};
