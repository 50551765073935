// packages
import axios from "axios";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// classes
import Diamond from "../../shared/classes/Diamond";
import Jewelry from "../../shared/classes/Jewelry";

// assets
import "swiper/css";

// utils
import { formattedCurrency } from "../../shared/utils/currency";

export default function SimilarItems({ type, id }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/companies/${process.env.REACT_APP_COMPANY_ID}/${type === "diamond" ? "stone" : "jewelry"}-similar/${id}.json`,
      )
      .then((response) => {
        if (type === "diamond") {
          setData(
            response.data.data.map((item) => {
              return new Diamond(item, "/diamonds");
            }),
          );
        } else if (type === "jewelry") {
          setData(
            response.data.data.map((item) => {
              return new Jewelry(item, "/jewelry");
            }),
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching similar items:", error);
      });
  }, []);

  return (
    data &&
    data.length > 0 && (
      <div>
        <h3 className="s-h3 my-4 border-b border-black/5 pb-4 text-center uppercase">
          Similar Items
        </h3>

        {data.length < 4 ? (
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4 lg:grid-cols-4">
            {data.map((item, index) => {
              return (
                <NavLink
                  key={index}
                  className="border-hover group relative border border-black/5 p-4"
                  to={item.route}
                  target="_blank"
                >
                  <h4 className="s-h4 mb-2 h-12 text-center text-xs">
                    {location === "bookmark"
                      ? `${item.item.description}${item.item.description_2}`
                      : location === "compare"
                        ? `${item.data.description} ${item.data.description_2}`
                        : item.title}
                  </h4>
                  <div className="flex justify-center overflow-hidden py-2">
                    <img
                      alt={item.images ? item.images.main.alt : item.title}
                      className="max-h-36 object-cover transition-all duration-300 group-hover:scale-110"
                      loading="lazy"
                      src={item.images.main.src}
                    />
                  </div>
                  <div className="mt-2 flex items-center justify-center gap-2">
                    <p className="font-body font-bold">
                      {formattedCurrency(item.data.price_total_4)}
                    </p>
                  </div>
                </NavLink>
              );
            })}
          </div>
        ) : (
          <Swiper
            modules={[Autoplay, Pagination]}
            autoplay={{
              delay: 2500,
            }}
            loop={true}
            slidesPerView={1}
            spaceBetween={10}
            speed={1500}
            pagination={{
              enabled: true,
              clickable: true,
              dynamicBullets: true,
            }}
            breakpoints={{
              550: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            style={{
              "--swiper-pagination-color": "#404040",
              "--swiper-pagination-bullet-inactive-color": "#c6a58e",
              "--swiper-pagination-bullet-size": "1rem",
              "--swiper-pagination-bullet-border-radius": "0",
            }}
          >
            {data.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <NavLink
                    key={index}
                    className="border-hover group relative mb-8 block w-full border border-black/5 p-4"
                    to={item.route}
                    target="_blank"
                  >
                    <h4 className="s-h4 mb-2 h-12 text-center text-xs">
                      {location === "bookmark"
                        ? `${item.item.description}${item.item.description_2}`
                        : location === "compare"
                          ? `${item.data.description} ${item.data.description_2}`
                          : item.title}
                    </h4>
                    <div className="flex justify-center overflow-hidden py-2">
                      <img
                        alt={item.images ? item.images.main.alt : item.title}
                        className="max-h-36 object-cover transition-all duration-300 group-hover:scale-110"
                        loading="lazy"
                        src={item.images ? item.images.main.src : ImageIcon}
                      />
                    </div>
                    <div className="mt-2 flex items-center justify-center gap-2">
                      <p className="font-body font-bold">
                        {formattedCurrency(item.data.price_total_4)}
                      </p>
                    </div>
                  </NavLink>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    )
  );
}
