// packages
import _ from "lodash";
import { PiTrashLight } from "react-icons/pi";

// components
import Alert from "../components/Alert";
import ItemGrid from "../components/ItemGrid";
// contexts
import { useComparison } from "../contexts/ComparisonContext";

const DEFAULT_THEME = {
  button: {
    primary: "bg-t-beige text-white",
  },
};

export default function Compare({ columns, theme }) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  const { comparisonData, comparisonType, updateComparisonData } =
    useComparison();

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  return (
    <>
      {comparisonData.length > 0 ? (
        <>
          <div className="mt-2 flex justify-end md:mt-4">
            <button
              key="clearButton"
              onClick={() => updateComparisonData([])}
              title="Remove all items from comparison"
              className={`${activeTheme.button.primary} flex w-fit items-center px-2 py-1.5 text-sm`}
            >
              <PiTrashLight
                className="mr-1 shrink-0"
                size="16"
              />
              <span className="pt-1">Remove All</span>
            </button>
          </div>
          <ItemGrid
            data={comparisonData}
            location="compare"
          />
        </>
      ) : (
        <Alert
          content={`Nothing to compare yet, select some ${comparisonType} for comparison.`}
          type="warning"
          visible={true}
        />
      )}
    </>
  );
}
