// packages
import classNames from "classnames";
import _ from "lodash";
import { useEffect, useState } from "react";
import { PiGridNineLight, PiTableLight } from "react-icons/pi";
import Select from "react-select";

// classes
import Diamond from "../classes/Diamond";

// components
import Accordion from "./Accordion";
import Compare from "./Compare";
import ItemGrid from "./ItemGrid";
import Pagination from "./Pagination";
import PickItems from "./PickItems";
import RangeNumeric from "./RangeNumeric";
import SelectImages from "./SelectImages";
import Table from "./Table";
import Tabs from "./Tabs";

// contexts
import { useComparison } from "../contexts/ComparisonContext";
import { useSearch } from "../contexts/SearchContext";

// utils
import { formattedCurrency } from "../utils/currency";
import { isClientUser } from "../utils/user";

const DEFAULT_THEME = {
  accordion: {
    expandButton: {
      all: "",
      active: "bg-gray-600 text-white",
      inactive: "bg-t-red text-white",
    },
  },
  button: {
    primary: "bg-gray-600 text-white hover:bg-t-red hover:text-white",
  },
  compare: {
    button: {
      primary: "bg-gray-600 text-white hover:bg-t-red hover:text-white",
    },
  },
  input: "border-gray-600 focus:border-t-red",
  multiselect: {
    title: "text-black",
    select: {
      control: ({ isDisabled, isFocused }) =>
        classNames(
          !isDisabled && isFocused && "border-t-red",
          isFocused && "shadow-[0_0_0_1px] shadow-t-red",
          isFocused && "hover:border-t-red",
        ),
      option: ({ isDisabled, isFocused, isSelected }) =>
        classNames(
          isSelected && "bg-t-red",
          !isSelected && isFocused && "bg-t-red text-white",
          !isDisabled && isSelected && "active:bg-t-red",
          !isDisabled && !isSelected && "active:bg-t-red",
        ),
    },
  },
  radio:
    "border-gray-400 bg-gray-50 hover:bg-gray-200 checked:bg-t-red checked:hover:bg-t-red/50 checked:focus:bg-t-red/50",
  rangeNumeric: {
    input: "border-gray-600 focus:border-t-red",
    mark: "bg-t-red",
    thumb: "bg-t-red text-white focus:ring-t-red/40",
    title: "text-black",
    track: {
      active: "bg-t-red",
      inactive: "bg-gray-400",
    },
  },
  rangeValues: {
    label: {
      active: "text-black",
      inactive: "text-gray-400",
    },
    mark: "bg-t-red",
    thumb: "bg-t-red text-white focus:ring-t-red/40",
    title: "text-black",
    track: {
      active: "bg-t-red",
      inactive: "bg-gray-400",
    },
  },
  selectImages: {
    option: {
      all: "",
      active: "border-t-red bg-gray-50",
      hover: "hover:bg-gray-100",
      inactive: "border-white",
    },
    title: "text-black",
  },
};

export default function DiamondsUI({ theme = {} }) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  //////////////////////////////////////////////////////////////////////////////
  // contexts
  //////////////////////////////////////////////////////////////////////////////
  const {
    // data
    results,

    // search
    searchParams,
    setSearchParams,
    setCurrentPage,
    searchProperties,
    // handleSearch,
    handleInputChange,
    fetchSearchProperties,
    expandAdvancedSearch,

    // reset
    handleReset,

    // sort
    // handleSort,

    // pagination
    currentPage,
    totalPages,
    handlePageChange,

    // routes
    routeShow,
  } = useSearch();

  const { comparisonData } = useComparison();

  //////////////////////////////////////////////////////////////////////////////
  // search
  //////////////////////////////////////////////////////////////////////////////

  const [paramDescription, setParamDescription] = useState(
    searchParams.description,
  );

  const handleParamDescription = (e) => {
    const previousValue = e.target.getAttribute("data-previous-value") || "";

    if (previousValue !== paramDescription) {
      setSearchParams((prevParams) => ({
        ...prevParams,
        description: paramDescription,
      }));
      setCurrentPage(1);
      handleInputChange(e);
    }
  };

  //////////////////////////////////////////////////////////////////////////////
  // user
  //////////////////////////////////////////////////////////////////////////////

  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    // const profile = JSON.parse(localStorage.profile);
    // setUserRole(profile.role);
    // fetchSearchProperties(profile.company_id);
    fetchSearchProperties(process.env.REACT_APP_COMPANY_ID);
  }, []);

  //////////////////////////////////////////////////////////////////////////////
  // table columns
  //////////////////////////////////////////////////////////////////////////////

  const tableColumns = () => {
    let columns = [
      {
        key: "shapeDetails",
        label: "Shape",
        sortKey: "shape_code",
        classNames: "text-center",
      },
    ];

    // if (
    //   searchParams.stoneType // &&
    //   // searchParams.stoneType !== "DIAM" &&
    //   // searchParams.stoneType !== "LGD"
    // ) {
    //   columns.push({
    //     key: "stoneType",
    //     label: "Type",
    //     sortKey: "stone_type",
    //   });
    // }

    if (searchParams.description) {
      columns.push({
        key: "stockNumber",
        label: "Stock #",
        sortKey: "item_no",
      });
    }

    columns.push(
      {
        key: "shape",
        label: "Shape",
        sortKey: "shape_name",
        link: true,
        path: routeShow,
        classNames: "text-center",
      },
      {
        key: "carats",
        label: "Carats",
        sortKey: "carats",
        classNames: "text-right",
      },
      {
        key: "color",
        label: "Color",
        sortKey: "color_code",
        classNames: "text-center",
      },
      {
        key: "clarity",
        label: "Clarity",
        sortKey: "clarity_code",
        classNames: "text-center",
      },
      {
        key: "cut",
        label: "Cut",
        sortKey: "cut_code",
        classNames: "text-center",
      },
      // {
      //   key: "measurements",
      //   label: "Measurements",
      //   sortKey: "measurements",
      // },
    );

    if (searchParams.polish && searchParams.polish.length > 0) {
      columns.push({
        key: "polish",
        label: "Polish",
        sortKey: "polish_code",
        classNames: "text-center",
      });
    }

    if (searchParams.symmetry && searchParams.symmetry.length > 0) {
      columns.push({
        key: "symmetry",
        label: "Symmetry",
        sortKey: "symmetry_code",
        classNames: "text-center",
      });
    }

    if (searchParams.fluorescence && searchParams.fluorescence.length > 0) {
      columns.push({
        key: "fluorescence",
        label: "Fluorescence",
        sortKey: "fluorescence_code",
        classNames: "text-center",
      });
    }

    if (searchParams.tableMin || searchParams.tableMax) {
      columns.push({
        key: "table",
        label: "Table",
        sortKey: "table",
        classNames: "text-center",
      });
    }

    if (searchParams.depthMin || searchParams.depthMax) {
      columns.push({
        key: "depth",
        label: "Depth",
        sortKey: "depth",
        classNames: "text-center",
      });
    }

    if (searchParams.lab && searchParams.lab.length > 0) {
      columns.push({ key: "lab", label: "Lab", sortKey: "lab_name" });
    }

    columns.push({
      key: "price",
      label: "Price",
      sortKey: "price_total_2",
      classNames: "text-right",
    });

    return columns;
  };

  //////////////////////////////////////////////////////////////////////////////
  // item details
  //////////////////////////////////////////////////////////////////////////////

  const detailSettings = {
    keys: [
      "stockNumber",
      "stoneType",
      "lab",
      "certificate",
      "origin",
      "polish",
      "symmetry",
      "fluorescence",
      "depth",
      "table",
      "measurements",
      "size",
      "ratio",
      "culet",
      "girdle",
    ],
  };

  // const modalSettings = {
  //   detailsLinkPath: "diamonds",
  //   columns: [
  //     { key: "item_no", header: true },
  //     { key: "carats", label: "Carats" },
  //     { key: "clarity", label: "Clarity" },
  //     { key: "color", label: "Color" },
  //     { key: "comments", label: "Comments" },
  //     { key: "cut", label: "Cut" },
  //     { key: "description", label: "Description" },
  //     { key: "lab", label: "Lab" },
  //     { key: "price", label: "Price" },
  //     { key: "shape", label: "Shape" },
  //   ],
  // };

  //////////////////////////////////////////////////////////////////////////////
  // view switcher
  //
  // TODO: refactor into component to remove duplication
  //////////////////////////////////////////////////////////////////////////////

  const [viewType, setViewType] = useState(
    localStorage.getItem("viewType") || "table",
  );

  useEffect(() => {
    localStorage.setItem("viewType", viewType);
  }, [viewType]);

  const paginationAndViewSwitcher = (
    <div className="flex justify-between">
      <Pagination
        currentPage={currentPage - 1}
        onPageChange={handlePageChange}
        pageCount={totalPages}
      />
      <div className="inline-flex">
        <button>
          <PiGridNineLight
            className={`${viewType === "grid" && "text-t-beige"} shrink-0`}
            onClick={() => setViewType("grid")}
            size={24}
          />
        </button>
        <button>
          <PiTableLight
            className={`${viewType === "table" && "text-t-beige"} shrink-0`}
            onClick={() => setViewType("table")}
            size={24}
          />
        </button>
      </div>
    </div>
  );

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const tabData = [
    {
      label: "Search",
      content: (
        <div className="mt-2 flex flex-col gap-4 md:mt-4 md:flex-row">
          <aside className="flex h-auto flex-col gap-4 md:w-1/4">
            <button
              className={`${activeTheme.button.primary} p-1.5 text-sm`}
              onClick={handleReset}
            >
              Reset Search
            </button>
            <SelectImages
              title="Shape"
              name="shape"
              selectedValues={searchParams.shape}
              // theme={activeTheme.selectImages}
              onChange={(e) => {
                setSearchParams((prevParams) => ({
                  ...prevParams,
                  shape: e.target.value,
                }));
                setCurrentPage(1);
              }}
              data={Diamond.Shapes()}
            />
            <div>
              <p className="text-sm">Stone Type</p>
              <div className="mt-2 flex justify-between gap-2">
                <label className="flex items-center gap-2 text-sm">
                  <input
                    id="all"
                    checked={
                      !searchParams.stoneType || searchParams.stoneType === ""
                    }
                    className={`${activeTheme.radio} focus:ring-0`}
                    name="stoneType"
                    onChange={(e) => {
                      setSearchParams((prevParams) => ({
                        ...prevParams,
                        stoneType: e.target.value,
                      }));
                      setCurrentPage(1);
                      handleInputChange(e);
                    }}
                    type="radio"
                    value=""
                  />
                  All
                </label>
                <label className="flex items-center gap-2 text-sm">
                  <input
                    id="natural"
                    checked={searchParams.stoneType === "DIAM"}
                    className={`${activeTheme.radio} focus:ring-0`}
                    name="stoneType"
                    onChange={(e) => {
                      setSearchParams((prevParams) => ({
                        ...prevParams,
                        stoneType: e.target.value,
                      }));
                      setCurrentPage(1);
                      handleInputChange(e);
                    }}
                    type="radio"
                    value="DIAM"
                  />
                  Natural
                </label>
                <label className="flex items-center gap-2 text-sm">
                  <input
                    id="labGrown"
                    checked={searchParams.stoneType === "LGD"}
                    className={`${activeTheme.radio} focus:ring-0`}
                    name="stoneType"
                    onChange={(e) => {
                      setSearchParams((prevParams) => ({
                        ...prevParams,
                        stoneType: e.target.value,
                      }));
                      setCurrentPage(1);
                      handleInputChange(e);
                    }}
                    type="radio"
                    value="LGD"
                  />
                  Lab Grown
                </label>
                {/* <label className="flex items-center gap-2 text-sm">
                  <input
                    id="other"
                    checked={searchParams.stoneType === "OTHER"}
                    className={`${activeTheme.radio} focus:ring-0`}
                    name="stoneType"
                    onChange={(e) => {
                      setSearchParams((prevParams) => ({
                        ...prevParams,
                        stoneType: e.target.value,
                      }));
                      setCurrentPage(1);
                      handleInputChange(e);
                    }}
                    type="radio"
                    value="OTHER"
                  />
                  Other
                </label> */}
              </div>
            </div>
            {/* TODO: fix valueCalculator and positionCalculator */}
            <RangeNumeric
              title="Price"
              limitMax={searchProperties.price_max}
              limitMin={searchProperties.price_min}
              // markMax={239}
              // markMin={0}
              // marks={[10, 109, 149]}
              // theme={activeTheme.rangeNumeric}
              valMax={searchParams.priceMax}
              valMin={searchParams.priceMin}
              callback={(min, max) => {
                setSearchParams((prevParams) => ({
                  ...prevParams,
                  priceMin: min,
                  priceMax: max,
                }));
                setCurrentPage(1);
              }}
              valueFormatter={(value) => {
                return formattedCurrency(value, 0);
              }}
              // valueCalculator={(number) => {
              //   if (number <= 10) {
              //     // increase by $100 up to $1,000
              //     return number * 100;
              //   } else if (number <= 109) {
              //     // increase by $1,000 up to $100,000
              //     return 1000 + (number - 10) * 1000;
              //   } else if (number <= 149) {
              //     // increase by $10,000 up to $500,000
              //     return 100_000 + (number - 109) * 10_000;
              //   } else if (number <= 239) {
              //     // increase by $50,000 up to $5,000,000
              //     return 500_000 + (number - 149) * 50_000;
              //   }
              // }}
              // positionCalculator={(value) => {
              //   if (value <= 1000) {
              //     // Convert $100 increments up to $1000
              //     return value / 100;
              //   } else if (value <= 100000) {
              //     // Convert $1000 increments up to $100,000
              //     return 10 + (value - 1000) / 1000;
              //   } else if (value <= 500000) {
              //     // Convert $10,000 increments up to $500,000
              //     return 109 + (value - 100000) / 10000;
              //   } else if (value <= 5000000) {
              //     // Convert $50,000 increments up to $5,000,000
              //     return 149 + (value - 500000) / 50000;
              //   } else {
              //     // Set the position to the maximum mark if value exceeds the maximum
              //     return 239;
              //   }
              // }}
            />
            <RangeNumeric
              title="Carats"
              limitMax={searchProperties.carats_max}
              limitMin={searchProperties.carats_min}
              step={0.01}
              // theme={activeTheme.rangeNumeric}
              valMax={searchParams.caratsMax}
              valMin={searchParams.caratsMin}
              callback={(min, max) => {
                setSearchParams((prevParams) => ({
                  ...prevParams,
                  caratsMin: min,
                  caratsMax: max,
                }));
                setCurrentPage(1);
              }}
            />
            <PickItems
              title="Cut"
              options={Diamond.Cuts()}
              selectedValues={searchParams.cut}
              callback={(value) => {
                const newCutValues = _.includes(searchParams.cut, value)
                  ? _.without(searchParams.cut, value)
                  : [...searchParams.cut, value];
                setSearchParams((prevParams) => ({
                  ...prevParams,
                  cut: newCutValues,
                }));
                setCurrentPage(1);
              }}
            />
            <PickItems
              title="Color"
              theme={{ gridClass: "grid-cols-6" }}
              options={Diamond.Colors()}
              selectedValues={searchParams.color}
              callback={(value) => {
                const newColorValues = _.includes(searchParams.color, value)
                  ? _.without(searchParams.color, value)
                  : [...searchParams.color, value];

                setSearchParams((prevParams) => ({
                  ...prevParams,
                  color: newColorValues,
                }));
                setCurrentPage(1);
              }}
            />
            <PickItems
              title="Clarity"
              options={Diamond.Clarities()}
              selectedValues={searchParams.clarity}
              theme={{ gridClass: "grid-cols-6" }}
              callback={(value) => {
                const newClarityValues = _.includes(searchParams.clarity, value)
                  ? _.without(searchParams.clarity, value)
                  : [...searchParams.clarity, value];

                setSearchParams((prevParams) => ({
                  ...prevParams,
                  clarity: newClarityValues,
                }));
                setCurrentPage(1);
              }}
            />
            <Accordion
              isFirstTabActive={expandAdvancedSearch(searchParams)}
              // theme={activeTheme.accordion}
              data={[
                {
                  label: "Advanced Search",
                  content: (
                    <div className="mb-2 flex flex-col gap-6">
                      <div className="mt-2">
                        <p className="text-sm">Item Number or Description</p>
                        <input
                          className={`${activeTheme.input} mt-2 w-full text-sm focus:ring-0`}
                          data-previous-value=""
                          name="description"
                          onBlur={(e) => {
                            handleParamDescription(e);
                          }}
                          onChange={(e) => {
                            setParamDescription(e.target.value);
                          }}
                          onFocus={(e) => {
                            e.target.setAttribute(
                              "data-previous-value",
                              e.target.value,
                            );
                          }}
                          onKeyDown={(e) => {
                            if (e.code === "Enter") {
                              handleParamDescription(e);
                            }
                          }}
                          placeholder="Search by item number or description"
                          type="text"
                          value={paramDescription}
                        />
                      </div>
                      <PickItems
                        title="Polish"
                        options={Diamond.Polishes()}
                        selectedValues={searchParams.polish}
                        theme={{
                          gridClass: "grid-cols-5",
                          textClass: "text-2xs sm:text-3xs lg:text-2xs",
                        }}
                        callback={(value) => {
                          const newPolishValues = _.includes(
                            searchParams.polish,
                            value,
                          )
                            ? _.without(searchParams.polish, value)
                            : [...searchParams.polish, value];

                          setSearchParams((prevParams) => ({
                            ...prevParams,
                            polish: newPolishValues,
                          }));
                          setCurrentPage(1);
                        }}
                      />
                      <PickItems
                        title="Symmetry"
                        options={Diamond.Symmetries()}
                        theme={{
                          gridClass: "grid-cols-5",
                          textClass: "text-2xs sm:text-3xs lg:text-2xs",
                        }}
                        selectedValues={searchParams.symmetry}
                        callback={(value) => {
                          const newSymmetryValues = _.includes(
                            searchParams.symmetry,
                            value,
                          )
                            ? _.without(searchParams.symmetry, value)
                            : [...searchParams.symmetry, value];

                          setSearchParams((prevParams) => ({
                            ...prevParams,
                            symmetry: newSymmetryValues,
                          }));
                          setCurrentPage(1);
                        }}
                      />
                      <PickItems
                        title="Fluorescence"
                        options={Diamond.Fluorescences()}
                        theme={{
                          gridClass: "grid-cols-5",
                          textClass: "text-2xs sm:text-3xs lg:text-2xs",
                        }}
                        selectedValues={searchParams.fluorescence}
                        callback={(value) => {
                          const newFluorescenceValues = _.includes(
                            searchParams.fluorescence,
                            value,
                          )
                            ? _.without(searchParams.fluorescence, value)
                            : [...searchParams.fluorescence, value];

                          setSearchParams((prevParams) => ({
                            ...prevParams,
                            fluorescence: newFluorescenceValues,
                          }));
                          setCurrentPage(1);
                        }}
                      />
                      <RangeNumeric
                        title="Table (%)"
                        limitMax={searchProperties.table_max}
                        limitMin={searchProperties.table_min}
                        step={0.1}
                        // theme={activeTheme.rangeNumeric}
                        valMax={searchParams.tableMax}
                        valMin={searchParams.tableMin}
                        callback={(min, max) => {
                          setSearchParams((prevParams) => ({
                            ...prevParams,
                            tableMin: min,
                            tableMax: max,
                          }));
                          setCurrentPage(1);
                        }}
                      />
                      <RangeNumeric
                        title="Depth (%)"
                        limitMax={searchProperties.depth_max}
                        limitMin={searchProperties.depth_min}
                        step={0.1}
                        // theme={activeTheme.rangeNumeric}
                        valMax={searchParams.depthMax}
                        valMin={searchParams.depthMin}
                        callback={(min, max) => {
                          setSearchParams((prevParams) => ({
                            ...prevParams,
                            depthMin: min,
                            depthMax: max,
                          }));
                          setCurrentPage(1);
                        }}
                      />
                      <div>
                        <p
                          className={`${activeTheme.multiselect.title} mb-2 text-sm`}
                        >
                          Lab
                        </p>
                        <Select
                          isClearable={true}
                          isMulti={true}
                          classNames={activeTheme.multiselect.select}
                          onChange={(values, _action) => {
                            setSearchParams((prevParams) => ({
                              ...prevParams,
                              lab: values.map((item) => item.value),
                            }));
                            setCurrentPage(1);
                          }}
                          options={
                            searchProperties.lab &&
                            searchProperties.lab.map((item) => ({
                              label: item,
                              value: item,
                            }))
                          }
                          placeholder="Lab"
                          value={searchParams.lab.map((item) => ({
                            label: item,
                            value: item,
                          }))}
                        />
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          </aside>
          <main className="flex-1 overflow-x-auto">
            {paginationAndViewSwitcher}
            {viewType === "grid" && (
              <div className="my-2 md:my-4">
                <ItemGrid
                  data={results}
                  quoteType="diamond"
                />
              </div>
            )}
            {viewType === "table" && (
              <div className="my-2 md:my-4">
                <Table
                  data={results}
                  columns={tableColumns().filter(
                    (column) => column.key !== "shape",
                  )}
                  // detailSettings={detailSettings}
                  quoteType={isClientUser(userRole) && "diamond"}
                  enableSorting={true}
                  showBookmarks={true}
                />
              </div>
            )}
            {paginationAndViewSwitcher}
          </main>
        </div>
      ),
    },
    {
      label: `Compare (${comparisonData.length})`,
      content: (
        <Compare
          columns={tableColumns().filter(
            (column) => column.key !== "shapeDetails",
          )}
          // theme={activeTheme.compare}
        />
      ),
    },
  ];

  return <Tabs data={tabData} />;
}
