// packages
import classNames from "classnames";
import _ from "lodash";
import { useEffect, useState } from "react";
import Select from "react-select";

// classes
import Jewelry from "../classes/Jewelry";

// components
import Compare from "./Compare";
import ItemGrid from "./ItemGrid";
import Pagination from "./Pagination";
import RangeNumeric from "./RangeNumeric";
import SelectImages from "./SelectImages";
import Tabs from "./Tabs";

// contexts
import { useComparison } from "../contexts/ComparisonContext";
import { useSearch } from "../contexts/SearchContext";

// utils
import { formattedCurrency } from "../utils/currency";

const DEFAULT_THEME = {
  accordion: {
    expandButton: {
      all: "",
      active: "bg-gray-600 text-white",
      inactive: "bg-t-red text-white",
    },
  },
  button: {
    primary: "bg-gray-600 text-white hover:bg-t-red hover:text-white",
  },
  compare: {
    button: {
      primary: "bg-gray-600 text-white hover:bg-t-red hover:text-white",
    },
  },
  input: "border-gray-600 focus:border-t-red",
  multiselect: {
    title: "text-black",
    select: {
      control: ({ isDisabled, isFocused }) =>
        classNames(
          !isDisabled && isFocused && "border-t-red",
          isFocused && "shadow-[0_0_0_1px] shadow-t-red",
          isFocused && "hover:border-t-red",
        ),
      option: ({ isDisabled, isFocused, isSelected }) =>
        classNames(
          isSelected && "bg-t-red",
          !isSelected && isFocused && "bg-t-red text-white",
          !isDisabled && isSelected && "active:bg-t-red",
          !isDisabled && !isSelected && "active:bg-t-red",
        ),
    },
  },
  radio:
    "border-gray-400 bg-gray-50 hover:bg-gray-200 checked:bg-t-red checked:hover:bg-t-red/50 checked:focus:bg-t-red/50",
  rangeNumeric: {
    input: "border-gray-600 focus:border-t-red",
    mark: "bg-t-red",
    thumb: "bg-t-red text-white focus:ring-t-red/40",
    title: "text-black",
    track: {
      active: "bg-t-red",
      inactive: "bg-gray-400",
    },
  },
  rangeValues: {
    label: {
      active: "text-black",
      inactive: "text-gray-400",
    },
    mark: "bg-t-red",
    thumb: "bg-t-red text-white focus:ring-t-red/40",
    title: "text-black",
    track: {
      active: "bg-t-red",
      inactive: "bg-gray-400",
    },
  },
  selectImages: {
    option: {
      all: "",
      active: "border-t-red bg-gray-50",
      hover: "hover:bg-gray-100",
      inactive: "border-white",
    },
    title: "text-black",
  },
};

export default function JewelryUI({ theme = {} }) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  //////////////////////////////////////////////////////////////////////////////
  // contexts
  //////////////////////////////////////////////////////////////////////////////
  const {
    // data
    results,

    // search
    searchParams,
    setSearchParams,
    setCurrentPage,
    searchProperties,
    // handleSearch,
    handleInputChange,
    fetchSearchProperties,
    expandAdvancedSearch,

    // reset
    handleReset,

    // sort
    // handleSort,

    // pagination
    currentPage,
    totalPages,
    handlePageChange,

    // routes
    routeShow,
  } = useSearch();

  const { comparisonData } = useComparison();

  //////////////////////////////////////////////////////////////////////////////
  // search
  //////////////////////////////////////////////////////////////////////////////

  const [paramDescription, setParamDescription] = useState(
    searchParams.description,
  );

  const handleParamDescription = (e) => {
    const previousValue = e.target.getAttribute("data-previous-value") || "";

    if (previousValue !== paramDescription) {
      setSearchParams((prevParams) => ({
        ...prevParams,
        description: paramDescription,
      }));
      setCurrentPage(1);
      handleInputChange(e);
    }
  };

  //////////////////////////////////////////////////////////////////////////////
  // user
  //////////////////////////////////////////////////////////////////////////////

  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    // const profile = JSON.parse(localStorage.profile);
    // setUserRole(profile.role);
    // fetchSearchProperties(profile.company_id);
    fetchSearchProperties(process.env.REACT_APP_COMPANY_ID);
  }, []);

  const paginationAndViewSwitcher = (
    <div className="flex justify-between">
      <Pagination
        currentPage={currentPage - 1}
        onPageChange={handlePageChange}
        pageCount={totalPages}
      />
    </div>
  );

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const tabData = [
    {
      label: "Search",
      content: (
        <div className="mt-2 flex flex-col gap-4 md:mt-4 md:flex-row">
          <aside className="flex h-auto flex-col gap-6 md:w-1/4">
            <button
              className={`${activeTheme.button.primary} p-1.5 text-sm`}
              onClick={handleReset}
            >
              Reset Search
            </button>
            <SelectImages
              title="Category"
              name="category"
              selectedValues={searchParams.category}
              // theme={activeTheme.selectImages}
              onChange={(e) => {
                setSearchParams((prevParams) => ({
                  ...prevParams,
                  category: e.target.value.map((value) => value.toUpperCase()),
                }));
                setCurrentPage(1);
              }}
              data={Jewelry.Categories()}
            />
            {/* TODO: fix valueCalculator and positionCalculator */}
            <RangeNumeric
              title="Price"
              limitMax={searchProperties.price_max}
              limitMin={searchProperties.price_min}
              // markMax={239}
              // markMin={0}
              // marks={[10, 109, 149]}
              // theme={activeTheme.rangeNumeric}
              valMax={searchParams.priceMax}
              valMin={searchParams.priceMin}
              callback={(min, max) => {
                setSearchParams((prevParams) => ({
                  ...prevParams,
                  priceMin: min,
                  priceMax: max,
                }));
                setCurrentPage(1);
              }}
              valueFormatter={(value) => {
                return formattedCurrency(value, 0);
              }}
            />
            {searchParams.category.length < 2 && (
              <div>
                <p className={`${activeTheme.multiselect.title} mb-2 text-sm`}>
                  Category
                </p>
                <Select
                  isClearable={true}
                  isMulti={false}
                  classNames={activeTheme.multiselect.select}
                  onChange={(values, _action) => {
                    setSearchParams((prevParams) => ({
                      ...prevParams,
                      category: values ? [values.value] : [],
                    }));
                    setCurrentPage(1);
                  }}
                  options={
                    searchProperties.category &&
                    Object.keys(searchProperties.category)
                      .sort()
                      .map((item) => ({
                        label: item,
                        value: item,
                      }))
                  }
                  placeholder="Category"
                  value={
                    searchParams.category.length > 0 && {
                      label: searchParams.category,
                      value: searchParams.category,
                    }
                  }
                />
              </div>
            )}
            {searchParams.category.length === 1 && (
              <div>
                <p className={`${activeTheme.multiselect.title} mb-2 text-sm`}>
                  Sub-Category
                </p>
                <Select
                  isClearable={true}
                  isMulti={true}
                  classNames={activeTheme.multiselect.select}
                  onChange={(values, _action) => {
                    setSearchParams((prevParams) => ({
                      ...prevParams,
                      subCategory: values.map((item) => item.value),
                    }));
                    setCurrentPage(1);
                  }}
                  options={(
                    _.get(searchProperties.category, searchParams.category) ||
                    []
                  )
                    .sort()
                    .map((item) => ({
                      label: item,
                      value: item,
                    }))}
                  placeholder="Sub-Category"
                  value={searchParams.subCategory.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              </div>
            )}
            <div>
              <p className={`${activeTheme.multiselect.title} mb-2 text-sm`}>
                Design
              </p>
              <Select
                isClearable={true}
                isMulti={true}
                classNames={activeTheme.multiselect.select}
                onChange={(values, _action) => {
                  setSearchParams((prevParams) => ({
                    ...prevParams,
                    design: values.map((item) => item.value),
                  }));
                  setCurrentPage(1);
                }}
                options={
                  searchProperties.design &&
                  searchProperties.design.map((item) => ({
                    label: item,
                    value: item,
                  }))
                }
                placeholder="Design"
                value={searchParams.design.map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
            </div>

            <div>
              <p className={`${activeTheme.multiselect.title} mb-2 text-sm`}>
                Metal Type
              </p>
              <Select
                isClearable={true}
                isMulti={true}
                classNames={activeTheme.multiselect.select}
                onChange={(values, _action) => {
                  setSearchParams((prevParams) => ({
                    ...prevParams,
                    metalType: values.map((item) => item.value),
                  }));
                  setCurrentPage(1);
                }}
                options={
                  searchProperties.metal_type &&
                  searchProperties.metal_type.map((item) => ({
                    label: item,
                    value: item,
                  }))
                }
                placeholder="Metal Type"
                value={searchParams.metalType.map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
            </div>
            <div>
              <p className={`${activeTheme.multiselect.title} mb-2 text-sm`}>
                Metal Color
              </p>
              <Select
                isClearable={true}
                isMulti={true}
                classNames={activeTheme.multiselect.select}
                onChange={(values, _action) => {
                  setSearchParams((prevParams) => ({
                    ...prevParams,
                    metalColor: values.map((item) => item.value),
                  }));
                  setCurrentPage(1);
                }}
                options={
                  searchProperties.metal_color &&
                  searchProperties.metal_color.map((item) => ({
                    label: item,
                    value: item,
                  }))
                }
                placeholder="Metal Color"
                value={searchParams.metalColor.map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
            </div>
            <div>
              <p className="text-sm">Item Number or Description</p>
              <input
                className={`${activeTheme.input} mt-2 w-full text-sm focus:ring-0`}
                data-previous-value=""
                name="description"
                onBlur={(e) => {
                  handleParamDescription(e);
                }}
                onChange={(e) => {
                  setParamDescription(e.target.value);
                }}
                onFocus={(e) => {
                  e.target.setAttribute("data-previous-value", e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    handleParamDescription(e);
                  }
                }}
                placeholder="Search by item number or description"
                type="text"
                value={paramDescription}
              />
            </div>
            {/* <Accordion
              isFirstTabActive={expandAdvancedSearch(searchParams)}
              // theme={activeTheme.accordion}
              data={[
                {
                  label: "Advanced Search",
                  content: (
                    <div className="mb-2 flex flex-col gap-6">
                      <PickItems
                        title="Metal Karat"
                        options={Jewelry.MetalKarats()}
                        theme={{
                          gridClass: "grid-cols-4",
                          // textClass: "text-2xs sm:text-3xs lg:text-2xs",
                        }}
                        selectedValues={searchParams.metalKarat}
                        callback={(value) => {
                          const newMetalKaratsValues = _.includes(
                            searchParams.metalKarat,
                            value,
                          )
                            ? _.without(searchParams.metalKarat, value)
                            : [...searchParams.metalKarat, value];

                          setSearchParams((prevParams) => ({
                            ...prevParams,
                            metalKarat: newMetalKaratsValues,
                          }));
                          setCurrentPage(1);
                        }}
                      />
                      <PickItems
                        title="Size"
                        options={Jewelry.Sizes()}
                        theme={{
                          gridClass: "grid-cols-6",
                          // textClass: "text-2xs sm:text-3xs lg:text-2xs",
                        }}
                        selectedValues={searchParams.size}
                        callback={(value) => {
                          const newSizeValues = _.includes(
                            searchParams.size,
                            value,
                          )
                            ? _.without(searchParams.size, value)
                            : [...searchParams.size, value];

                          setSearchParams((prevParams) => ({
                            ...prevParams,
                            size: newSizeValues,
                          }));
                          setCurrentPage(1);
                        }}
                      />
                    </div>
                  ),
                },
              ]}
            /> */}
          </aside>
          <main className="flex-1 overflow-x-auto">
            {paginationAndViewSwitcher}
            <div className="my-2 md:my-4">
              <ItemGrid
                data={results}
                quoteType="jewelry"
              />
            </div>
            {paginationAndViewSwitcher}
          </main>
        </div>
      ),
    },
    {
      label: `Compare (${comparisonData.length})`,
      content: <Compare />,
    },
  ];

  return <Tabs data={tabData} />;
}
