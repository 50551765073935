// packages
import axios from "axios";
import _ from "lodash";
import { useRef, useState } from "react";
import { PiArrowSquareRightLight, PiXSquareLight } from "react-icons/pi";
import Alert from "../shared/components/Alert";

// TODO: fix issue with modal not closing when clicking outside or pressing Esc
//  as currently implemented, it acts as a static modal instead of dynamic

const DEFAULT_THEME = {
  button: {
    primary: "bg-t-red text-t-skin hover:bg-t-gray hover:text-t-sky",
  },
  header: "text-t-black",
  input: "border-black/5 bg-gray-50 focus:border-t-beige",
  label: "text-t-black",
  overlay: "bg-t-black/90",
  window: "bg-t-skin",
};

export default function ModalInquiry({
  header,
  itemType,
  itemId,
  isModalOpen,
  setIsModalOpen,
  submitText = "Submit",
  theme = {},
}) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);
  const formRef = useRef(null);

  const [alertMessage, setAlertMessage] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [onSuccess, setOnSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlertVisible(false);

    const formData = new FormData(formRef.current);
    const entries = Object.fromEntries(formData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/companies/${process.env.REACT_APP_COMPANY_ID}/submit-contact-form`,
        {
          item_type: entries.itemType,
          item_id: entries.itemId,
          first_name: entries.name,
          last_name: entries.lastName,
          email: entries.email,
          phone: entries.phone,
          message: entries.comments,
        },
        {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      setAlertVisible(true);
      setAlertMessage("Your inquiry has been sent successfully.");
      setOnSuccess(true);
      setTimeout(() => {
        setIsModalOpen(false);
        setOnSuccess(false);
        setAlertVisible(false);
      }, 6000);
    } catch (error) {
      console.error("Error sending message:", error);
      setAlertVisible(true);
      setAlertMessage("Error sending message, please try again.");
      setOnSuccess(false);
    }
  };

  // https://flowbite.com/docs/components/modal/#default-modal
  return (
    isModalOpen && (
      <div
        inert={true}
        className={`${activeTheme.overlay} fixed left-0 right-0 top-0 z-50 h-full max-h-full w-full overflow-y-auto overflow-x-hidden max-md:px-2 md:inset-0`}
        tabIndex="-1"
      >
        <div className="relative flex h-full max-h-full w-full items-center justify-center">
          <div
            className={`${activeTheme.window} relative w-full space-y-5 border md:p-4 lg:w-5/12 xl:p-8`}
          >
            {/* header */}
            <button
              className="absolute right-5 top-5 bg-transparent text-t-beige hover:text-t-red"
              onClick={() => setIsModalOpen(false)}
              type="button"
            >
              <PiXSquareLight
                size={32}
                className="shrink-0"
              />
            </button>
            <div
              className={`${activeTheme.header} flex justify-center max-sm:px-4 max-sm:pt-8`}
            >
              <h3 className="s-h4">{header}</h3>
            </div>
            {/* content */}
            {alertVisible && (
              <Alert
                content={alertMessage}
                type={onSuccess ? "success" : "error"}
                visible={alertVisible}
              />
            )}
            {!onSuccess && (
              <form
                className="space-y-2 p-2 md:space-y-4"
                onSubmit={handleSubmit}
                ref={formRef}
              >
                <div className="grid w-full grid-cols-1 gap-2 sm:grid-cols-2 md:gap-4 xl:gap-6">
                  <input
                    type="text"
                    name="itemType"
                    defaultValue={itemType}
                    hidden
                  />
                  <input
                    type="text"
                    name="itemId"
                    defaultValue={itemId}
                    hidden
                  />
                  <div>
                    <input
                      className={`${activeTheme.input} w-full border p-2 focus:ring-0`}
                      id="iName"
                      name="name"
                      placeholder="First Name"
                      type="text"
                    />
                  </div>
                  <div>
                    <input
                      className={`${activeTheme.input} w-full border p-2 focus:ring-0`}
                      id="iLastName"
                      name="lastName"
                      placeholder="Last Name"
                      type="text"
                    />
                  </div>
                  <div>
                    <input
                      className={`${activeTheme.input} w-full border p-2 placeholder:text-t-red focus:ring-0`}
                      id="iEmail"
                      name="email"
                      placeholder="Email *"
                      required="required"
                      type="email"
                    />
                  </div>
                  <div>
                    <input
                      className={`${activeTheme.input} w-full border p-2 focus:ring-0`}
                      id="iPhone"
                      name="phone"
                      placeholder="Phone number"
                      type="tel"
                    />
                  </div>
                  <div className="col-span-full">
                    <textarea
                      className={`${activeTheme.input} w-full border p-2 focus:ring-0`}
                      id="iComments"
                      name="comments"
                      rows={6}
                      placeholder="Your questions or comments"
                    ></textarea>
                  </div>
                </div>
                <button
                  className={`${activeTheme.button.primary} s-text group mt-6 flex w-full items-center justify-center gap-2 px-4 py-3 font-medium focus:ring-0`}
                  type="submit"
                >
                  {submitText}
                  <PiArrowSquareRightLight
                    className="shrink-0"
                    size={20}
                  />
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    )
  );
}
