// packages
import _ from "lodash";

////////////////////////////////////////////////////////////////////////////////
// assets
////////////////////////////////////////////////////////////////////////////////

import ImageIcon from "../assets/images/icon/image-icon.svg";

// icons
// import CategorySketchAnklet from "../assets/images/jewelry/categories/w-anklet.png";
// import CategorySketchBrooch from "../assets/images/jewelry/categories/w-brooch.png";
// import CategorySketchCharm from "../assets/images/jewelry/categories/w-charm.png";
// import CategorySketchCufflink from "../assets/images/jewelry/categories/w-cufflink.png";
// import CategorySketchStud from "../assets/images/jewelry/categories/w-stud.png";
import CategorySketchBand from "../assets/images/jewelry/categories/w-band.png";
import CategorySketchBracelet from "../assets/images/jewelry/categories/w-bracelet.png";
import CategorySketchEarring from "../assets/images/jewelry/categories/w-earring.png";
import CategorySketchNecklace from "../assets/images/jewelry/categories/w-necklace.png";
import CategorySketchRing from "../assets/images/jewelry/categories/w-ring.png";

// photos - large
// TODO: need photos for these categories
// import CategoryPhotoAnklet from "../../assets/images/jewelry/categories/w-anklet.png";
// import CategoryPhotoBrooch from "../../assets/images/jewelry/categories/w-brooch.png";
// import CategoryPhotoCharm from "../../assets/images/jewelry/categories/w-charm.png";
// import CategoryPhotoCufflink from "../../assets/images/jewelry/categories/w-cufflink.png";
// import CategoryPhotoStud from "../../assets/images/jewelry/categories/w-stud.png";
import CategoryPhotoBand from "../../assets/images/jewelry/categories/photos/large/Band - 30383.avif";
import CategoryPhotoBracelet from "../../assets/images/jewelry/categories/photos/large/Bracelet - 30385.avif";
import CategoryPhotoEarring from "../../assets/images/jewelry/categories/photos/large/Earring - 25582.avif";
import CategoryPhotoNecklace from "../../assets/images/jewelry/categories/photos/large/Necklace - 31025.avif";
import CategoryPhotoRing from "../../assets/images/jewelry/categories/photos/large/Ring - 27610.avif";

// photos - thumbs
// TODO: need photos for these categories
// import CategoryThumbAnklet from "../../assets/images/jewelry/categories/w-anklet.png";
// import CategoryThumbBrooch from "../../assets/images/jewelry/categories/w-brooch.png";
// import CategoryThumbCharm from "../../assets/images/jewelry/categories/w-charm.png";
// import CategoryThumbCufflink from "../../assets/images/jewelry/categories/w-cufflink.png";
// import CategoryThumbStud from "../../assets/images/jewelry/categories/w-stud.png";
import CategoryThumbBand from "../../assets/images/jewelry/categories/photos/thumb/Band - 30383.avif";
import CategoryThumbBracelet from "../../assets/images/jewelry/categories/photos/thumb/Bracelet - 30385.avif";
import CategoryThumbEarring from "../../assets/images/jewelry/categories/photos/thumb/Earring - 25582.avif";
import CategoryThumbNecklace from "../../assets/images/jewelry/categories/photos/thumb/Necklace - 31025.avif";
import CategoryThumbRing from "../../assets/images/jewelry/categories/photos/thumb/Ring - 27610.avif";

////////////////////////////////////////////////////////////////////////////////

// utils
import { formattedCurrency } from "../utils/currency";

export default class Jewelry {
  //////////////////////////////////////////////////////////////////////////////
  // public
  //////////////////////////////////////////////////////////////////////////////

  constructor(data, showPath = null) {
    this.data = data;
    this.route = showPath ? `${showPath}/${data.id}` : data.id;

    this.id = data.id;
    this.type = data.type;
    this.is_bookmarked = data.is_bookmarked;

    // primary
    this.stockNumber = data.item_no;
    this.title = data.description + data.description_2;
    this.summary = data.description + data.description_2;
    this.centerDescription = data.center_description;
    this.sideDescription = data.side_description;
    this.categoryData = Jewelry.CategoryData(data);
    this.category = data.category;
    this.subCategory = data.sub_category;
    this.model = data.model;
    this.style = data.style;
    this.design = data.design;
    this.gender = data.gender;
    this.size = data.size;
    this.madeIn = data.made_in;

    // metals
    this.metalType = data.metal_1_type;
    this.metalKarat = data.metal_1_karat;
    this.metalColor = data.metal_1_color;
    this.metalWeight = this.#formatMetalWeight();

    // stones
    this.stoneDescription = data.stone_1_description;
    this.stoneCarats = data.stone_1_carats;
    this.stoneShape = data.stone_1_shape_code;
    this.stoneClarity = data.stone_1_clarity_code;
    this.stoneColor = data.stone_1_color_code;

    // price
    this.price = formattedCurrency(data.price_total, 0);

    // assets
    this.videoUrl = data.video_url;
    this.webUrl = data.web_url;
    this.images = {
      main: { alt: this.title, src: this.#imagePath() },
      thumb: { alt: this.title, src: this.#imagePath(true) },

      // TODO
      // gallery: [
      //   { alt: this.title, src: data.image_1_filename },
      //   { alt: this.title, src: data.image_2_filename },
      //   { alt: this.title, src: data.image_3_filename },
      //   { alt: this.title, src: data.image_4_filename },
      // ],
    };

    this.overview = {
      stockNumber: this.stockNumber,
      centerStone: this.centerDescription,
      sideDetails: this.sideDescription,
      category: this.category,
      subCategory: this.subCategory,
      model: this.model,
      style: this.style,
      design: this.design,
      size: this.size,
      metalType: this.metalType,
      metalKarat: this.metalKarat,
      metalColor: this.metalColor,
      metalWeight: this.metalWeight,
      madeIn: this.madeIn,
    };

    this.categoryDetails = (
      <div className="flex flex-col items-center justify-start gap-4 md:flex-row">
        {this.categoryData && (
          <img
            alt={this.title}
            className="w-6 object-cover"
            src={this.images.main.src}
            // src={this.categoryData.icon}
          />
        )}
        {this.data.category}
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////
  // private
  //////////////////////////////////////////////////////////////////////////////

  #imagePath(thumb = false) {
    if (this.data.images && this.data.images[0]) {
      return this.data.images[0];
    }

    if (this.categoryData) {
      if (thumb) {
        return this.categoryData.photos.thumb;
      } else {
        return ImageIcon;
      }
    }
    return ImageIcon;
  }

  #formatMetalWeight() {
    let metalWeight = "";

    if (this.data.metal_1_weight) {
      let metalWeightAsFloat = parseFloat(this.data.metal_1_weight);

      if (typeof metalWeightAsFloat === "number") {
        metalWeight = metalWeightAsFloat.toFixed(2);
      }
    }

    return metalWeight;
  }

  //////////////////////////////////////////////////////////////////////////////
  // static
  //////////////////////////////////////////////////////////////////////////////

  static CategoryData(item) {
    return Jewelry.Categories().find(
      (data) => _.lowerCase(data.value) === _.lowerCase(item.category),
    );
  }

  // definitions
  // TODO: replace with search properties from the API

  static Categories() {
    return [
      {
        label: "Rings",
        value: "Ring",
        icon: CategorySketchRing,
        photos: { main: CategoryPhotoRing, thumb: CategoryThumbRing },
      },
      {
        label: "Bands",
        value: "Band",
        icon: CategorySketchBand,
        photos: { main: CategoryPhotoBand, thumb: CategoryThumbBand },
      },
      {
        label: "Bracelets",
        value: "Bracelet",
        icon: CategorySketchBracelet,
        photos: {
          main: CategoryPhotoBracelet,
          thumb: CategoryThumbBracelet,
        },
      },
      {
        label: "Earrings",
        value: "Earring",
        icon: CategorySketchEarring,
        photos: { main: CategoryPhotoEarring, thumb: CategoryThumbEarring },
      },
      {
        label: "Necklaces",
        value: "Necklace",
        icon: CategorySketchNecklace,
        photos: {
          main: CategoryPhotoNecklace,
          thumb: CategoryThumbNecklace,
        },
      },
      // {
      //   label: "Pendants",
      //   value: "Pendant",
      //   icon: CategorySketchPendant,
      //   photos: { main: CategoryPhotoPendant, thumb: CategoryThumbPendant },
      // },
      // {
      //   label: "Semi-Mounts",
      //   value: "Semi-Mount",
      //   icon: CategorySketchSemiMount,
      //   photos: { main: CategoryPhotoSemiMount, thumb: CategoryThumbSemiMount },
      // },
      // {
      //   label: "Studs",
      //   value: "Stud",
      //   icon: CategorySketchStud,
      //   photos: { main: CategoryPhotoStud, thumb: CategoryThumbStud },
      // },
      // {
      //   label: "Anklets",
      //   value: "Anklet",
      //   icon: CategorySketchAnklet,
      //   photos: { main: CategoryPhotoAnklet, thumb: CategoryThumbAnklet },
      // },
      // {
      //   label: "Brooches",
      //   value: "Brooch",
      //   icon: CategorySketchBrooch,
      //   photos: { main: CategoryPhotoBrooch, thumb: CategoryThumbBrooch },
      // },
      // {
      //   label: "Charms",
      //   value: "Charm",
      //   icon: CategorySketchCharm,
      //   photos: { main: CategoryPhotoCharm, thumb: CategoryThumbCharm },
      // },
      // {
      //   label: "Cufflinks",
      //   value: "Cufflink",
      //   icon: CategorySketchCufflink,
      //   photos: {
      //     main: CategoryPhotoCufflink,
      //     thumb: CategoryThumbCufflink,
      //   },
      // },
    ];
  }

  static MetalKarats() {
    return {
      "24K": "24K",
      "22K": "22K",
      "21K": "21K",
      "20K": "20K",
      "18K": "18K",
      "14K": "14K",
      "10K": "10K",
      "9K": "9K",
    };
  }

  static Sizes() {
    return {
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      10: "10",
      11: "11",
      12: "12",
      13: "13",
    };
  }
}
