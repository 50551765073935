// packages
import { Component } from "react";

// classes
import Jewelry from "../classes/Jewelry";

// contexts
import { ComparisonProvider } from "../contexts/ComparisonContext";
import { SearchProvider } from "../contexts/SearchContext";

export default class JewelriesWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        // sorting
        sortBy: "",
        sortDir: "",
        // single values
        description: "",
        metalGramsMax: "",
        metalGramsMin: "",
        priceMax: "",
        priceMin: "",
        // multiple values
        category: [],
        metalKarat: [],
        metalColor: [],
        metalType: [],
        size: [],
        subCategory: [],
        design: [],
      },
    };
  }

  constructRequestUrl = (params, page) => {
    // construct API URL
    const url = new URL(`${process.env.REACT_APP_API_URL}/jewelries.json`);
    url.searchParams.append("company_id", process.env.REACT_APP_COMPANY_ID);

    // pagination & sorting
    url.searchParams.append("page", page);
    url.searchParams.append("sort_by", params.sortBy);
    url.searchParams.append("sort_dir", params.sortDir);

    // single values
    url.searchParams.append("description", params.description);
    url.searchParams.append("metal_weight_max", params.metalGramsMax);
    url.searchParams.append("metal_weight_min", params.metalGramsMin);
    url.searchParams.append("price_max", params.priceMax);
    url.searchParams.append("price_min", params.priceMin);

    // multiple values
    url.searchParams.append(
      "category",
      Array.isArray(params.category) ? params.category.join(",") : "",
    );
    url.searchParams.append("metal_color", params.metalColor.join(","));
    url.searchParams.append("design", params.design.join(","));
    url.searchParams.append("metal_karat", params.metalKarat.join(","));
    url.searchParams.append("metal_type", params.metalType.join(","));
    url.searchParams.append("size", params.size.join(","));
    url.searchParams.append("sub_category", params.subCategory.join(","));

    return url;
  };

  expandAdvancedSearch = (params) => {
    const expand =
      (params.metalKarat && params.metalKarat.length > 0) ||
      (params.size && params.size.length > 0) ||
      params.description ||
      params.metalGramsMax ||
      params.metalGramsMin;
    return !!expand;
  };

  formatData = (data) => {
    const { routeShow } = this.props;

    return data.map((item) => {
      return new Jewelry(item, routeShow);
    });
  };

  render() {
    const { params } = this.state;
    const { page: Page, routeShow } = this.props;

    return (
      <SearchProvider
        searchType="jewelry"
        params={params}
        requestUrl={this.constructRequestUrl}
        formatData={this.formatData}
        routeProperties="jewelry-properties"
        routeShow={routeShow}
        expandAdvancedSearch={this.expandAdvancedSearch}
      >
        <ComparisonProvider comparisonType="jewelry">
          {Page && <Page />}
        </ComparisonProvider>
      </SearchProvider>
    );
  }
}
