// packages
import axios from "axios";
import _ from "lodash";
import { useRef, useState } from "react";
import { PiXSquareLight } from "react-icons/pi";
// components
import Alert from "../shared/components/Alert";

// utils
import { fetchProfile } from "../shared/utils/user";

const PROFILE_KEY = "profile";
const TOKEN_KEY = "token";

const DEFAULT_THEME = {
  button: {
    primary: "bg-t-red text-t-skin hover:bg-t-gray hover:text-t-sky",
  },
  header: "bg-t-black text-white",
  input: "border-black/5 bg-gray-50 focus:border-t-beige",
  label: "text-gray-800",
  overlay: "bg-t-black/90",
  window: "bg-white",
  checkbox:
    "border-gray-400 bg-gray-50 rounded hover:bg-gray-200 checked:bg-theme-blue checked:hover:bg-theme-blue/50 checked:focus:bg-theme-blue/50",
};

export default function ModalLogin({
  header,
  isModalOpen,
  setIsModalOpen,
  theme = {},
  onSuccess,
}) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  const [alertMessage, setAlertMessage] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // login
  const loginFormRef = useRef();

  const handleCheckEmail = async (e) => {
    e.preventDefault();
    setAlertVisible(false);

    const formData = new FormData(loginFormRef.current);
    const entries = Object.fromEntries(formData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/first-login`,
        {
          user: {
            email: entries.email,
            company_id: process.env.REACT_APP_COMPANY_ID,
          },
        },
      );
      if (response.data.status === "user_can_login") {
        setShowPassword(true);
      } else {
        if (response.data.status === "user_password_email_sent") {
          setAlertVisible(true);
          setAlertMessage(
            "Check your email for instructions on setting your password",
          );
        } else {
          setAlertVisible(true);
          setAlertMessage("Email not found");
        }
      }
    } catch (error) {
      console.error("Error signing in:", error);
      setAlertVisible(true);
      setAlertMessage("Error signing in, please try again");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setAlertVisible(false);

    const formData = new FormData(loginFormRef.current);
    const entries = Object.fromEntries(formData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          user: {
            email: entries.email,
            password: entries.password,
            company_id: process.env.REACT_APP_COMPANY_ID,
          },
        },
        {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
          },
        },
      );

      localStorage.setItem(TOKEN_KEY, response.headers.authorization);

      const profile = await fetchProfile();
      if (profile) {
        localStorage.setItem(PROFILE_KEY, JSON.stringify(profile));
      }
      onSuccess();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error signing in:", error);
      setAlertVisible(true);
      setAlertMessage("Error signing in, please try again.");
    }
  };

  return (
    isModalOpen && (
      <div
        aria-hidden="true"
        className={`${activeTheme.overlay} fixed left-0 right-0 top-0 z-50 h-full max-h-full w-full overflow-y-auto overflow-x-hidden max-md:px-2 md:inset-0`}
        tabIndex="-1"
      >
        <div className="relative flex h-full max-h-full w-full items-center justify-center">
          <div className={`${activeTheme.window} relative w-full lg:w-5/12`}>
            {/* header */}
            <div
              className={`${activeTheme.header} flex items-center justify-between p-4`}
            >
              <h3 className="s-h4">{header}</h3>
              <button
                className="inline-flex items-center justify-center text-t-beige hover:text-t-skin"
                onClick={() => setIsModalOpen(false)}
                type="button"
              >
                <PiXSquareLight
                  size={32}
                  className="shrink-0"
                />
              </button>
            </div>
            {/* content */}
            {alertVisible && (
              <Alert
                content={alertMessage}
                type="error"
                visible={alertVisible}
              />
            )}
            <form
              className="space-y-2 p-2 md:space-y-4 md:p-4 xl:p-8"
              onSubmit={showPassword ? handleLogin : handleCheckEmail}
              ref={loginFormRef}
            >
              <div className="w-full space-y-2 md:space-y-4 xl:space-y-6">
                <div>
                  <input
                    className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0`}
                    id="lEmail"
                    name="email"
                    placeholder="Your email address"
                    required="required"
                    type="email"
                  />
                </div>
                {showPassword && (
                  <div>
                    <input
                      className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0`}
                      id="lPassword"
                      name="password"
                      placeholder="Your password"
                      required="required"
                      type="password"
                    />
                  </div>
                )}
              </div>
              <button
                className={`${activeTheme.button.primary} s-text group mt-6 flex w-full items-center justify-center gap-2 px-4 py-3 font-medium focus:ring-0`}
                type="submit"
              >
                Sign In
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  );
}
