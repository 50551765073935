// assets
import Image01 from "../../assets/images/catalog/1-Garvani_Catalog_2023_03.avif";
import Image10 from "../../assets/images/catalog/10-Garvani_Catalog_2023_18.avif";
import Image11 from "../../assets/images/catalog/11-Garvani_Catalog_2023_23.avif";
import Image12 from "../../assets/images/catalog/12-Garvani_Catalog_2023_25.avif";
import Image13 from "../../assets/images/catalog/13-Garvani_Catalog_2023_16.avif";
import Image14 from "../../assets/images/catalog/14-Garvani_Catalog_2023_02.avif";
import Image15 from "../../assets/images/catalog/15-Garvani_Catalog_2023_17.avif";
import Image16 from "../../assets/images/catalog/16-Garvani_Catalog_2023_21.avif";
import Image17 from "../../assets/images/catalog/17-Garvani_Catalog_2023_20.avif";
import Image18 from "../../assets/images/catalog/18-Garvani_Catalog_2023_15.avif";
import Image02 from "../../assets/images/catalog/2-Garvani_Catalog_2023_22.avif";
import Image03 from "../../assets/images/catalog/3-Garvani_Catalog_2023_05.avif";
import Image04 from "../../assets/images/catalog/4-Garvani_Catalog_2023_14.avif";
import Image05 from "../../assets/images/catalog/5-Garvani_Catalog_2023_09.avif";
import Image06 from "../../assets/images/catalog/6-Garvani_Catalog_2023_10.avif";
import Image07 from "../../assets/images/catalog/7-Garvani_Catalog_2023_01.avif";
import Image08 from "../../assets/images/catalog/8-Garvani_Catalog_2023_07.avif";
import Image09 from "../../assets/images/catalog/9-Garvani_Catalog_2023_08.avif";

import HeroImage from "../../assets/images/heroes/catalog.avif";

export const meta = {
  title: "Catalog",
  description: "",
  keywords: [""],
};

export const hero = {
  image: {
    src: HeroImage,
    alt: "",
    width: 1000,
    height: 1000,
  },
  contents: [
    "Step into our world of unrivaled beauty and extraordinary creations. Every piece is a testament to the highest standards of craftsmanship, where artistry meets innovation in the pursuit of perfection.",
    "From dazzling diamonds to meticulously crafted gold and platinum jewelry, our collection offers a range of breathtaking designs that exude sophistication and grace.",
    "Whether you seek a statement piece or an heirloom to cherish for generations, explore our collection and discover jewelry that transcends time and trends, embodying the essence of luxury and exclusivity.",
  ],
};

export const images = [
  {
    image: Image01,
  },
  {
    image: Image02,
  },
  {
    image: Image03,
  },
  {
    image: Image04,
  },
  {
    image: Image05,
  },
  {
    image: Image06,
  },
  {
    image: Image07,
  },
  {
    image: Image08,
  },
  {
    image: Image09,
  },
  {
    image: Image10,
  },
  {
    image: Image11,
  },
  {
    image: Image12,
  },
  {
    image: Image13,
  },
  {
    image: Image14,
  },
  {
    image: Image15,
  },
  {
    image: Image16,
  },
  {
    image: Image17,
  },
  {
    image: Image18,
  },
];
