import _ from "lodash";
import { useEffect, useState } from "react";

const DEFAULT_THEME = {
  option: {
    all: "",
    active: "border-black/20",
    hover: "hover:border-t-beige",
    inactive: "border-transparent",
  },
  title: "",
};

export default function SelectImages({
  data,
  name,
  onChange,
  selectedValues,
  theme,
  title,
}) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  // Ensure selectedValues is always an array
  const [values, setValues] = useState(
    Array.isArray(selectedValues) ? selectedValues : [],
  );

  useEffect(() => {
    setValues(Array.isArray(selectedValues) ? selectedValues : []);
  }, [selectedValues]);

  const handleClick = (value) => {
    let newValues = [...values];
    const index = newValues.findIndex(
      (item) => item.toLowerCase() === value.toLowerCase(),
    );

    if (index !== -1) {
      newValues.splice(index, 1);
    } else {
      newValues.push(value);
    }

    setValues(newValues);
    onChange({ target: { name, value: newValues } });
  };

  return (
    <div>
      <p className={`${activeTheme.title} text-sm`}>{title}</p>
      <div className="mt-2 grid grid-cols-3 gap-1 lg:grid-cols-4 xl:grid-cols-5">
        {data.map((item, idx) => (
          <button
            className={`${activeTheme.option.all} ${activeTheme.option.hover} ${
              values
                .map((item) => item.toLowerCase())
                .includes(item.value.toLowerCase())
                ? activeTheme.option.active
                : activeTheme.option.inactive
            } flex flex-col items-center gap-1 border p-2 md:gap-2`}
            key={idx}
            name={name}
            onClick={() => handleClick(item.value)}
          >
            <img
              className="h-12 object-contain"
              alt={item.label}
              src={item.photos.main}
            />
            <p className="text-xs">{item.label}</p>
          </button>
        ))}
      </div>
    </div>
  );
}
