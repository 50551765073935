// packages
import axios from "axios";
import "flowbite";
import { useEffect, useState } from "react";
import {
  PiChatLight,
  PiCurrencyCircleDollarLight,
  PiEnvelopeSimpleLight,
  PiHeartLight,
  PiListLight,
  PiPhoneLight,
  PiShoppingCartLight,
  PiSignOutLight,
  PiUserCircleLight,
  PiUserLight,
} from "react-icons/pi";
import { NavLink, useNavigate } from "react-router-dom";

// assets
import Logo from "../assets/logos/logo-dark.avif";

// data
import { meta } from "../data/meta";
import { navbar } from "../data/nav";

// utils
import { isTokenValid } from "../shared/utils/auth";
import { loadQuoteItems } from "../shared/utils/quote";
import { isCompanyUser } from "../shared/utils/user";

const PROFILE_KEY = "profile";
const TOKEN_KEY = "token";

export default function Navbar() {
  //////////////////////////////////////////////////////////////////////////////
  // dropdown & navigation
  //////////////////////////////////////////////////////////////////////////////

  const [openMainMenu, setOpenMainMenu] = useState(false);
  const navigate = useNavigate();

  //////////////////////////////////////////////////////////////////////////////
  // user
  //////////////////////////////////////////////////////////////////////////////

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profile, setProfile] = useState({});
  const [userMenu, setUserMenu] = useState(false);

  useEffect(() => {
    if (!isTokenValid()) {
      localStorage.removeItem(PROFILE_KEY);
    } else {
      setIsAuthenticated(true);

      if (localStorage.getItem(PROFILE_KEY)) {
        setProfile(JSON.parse(localStorage.getItem(PROFILE_KEY)));
      }
    }
  }, [localStorage.getItem(TOKEN_KEY)]);

  const handleLogout = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/logout`, {
        headers: {
          Authorization: localStorage.getItem(TOKEN_KEY),
        },
      });

      localStorage.removeItem(PROFILE_KEY);
      localStorage.removeItem(TOKEN_KEY);
      setProfile({});
      setIsAuthenticated(false);
      setUserMenu(!userMenu);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
      // alert("Error signing out, please try again.");
    }
  };

  const userNavData = {
    signedIn: [
      {
        href: "/profile",
        text: "Profile",
        icon: <PiUserLight className="shrink-0" />,
        onClick: () => setUserMenu(!userMenu),
      },
      {
        href: "/quotes",
        text: "Quotes",
        icon: <PiCurrencyCircleDollarLight className="shrink-0" />,
        onClick: () => setUserMenu(!userMenu),
      },
      {
        href: "/bookmarks",
        text: "Favorites",
        icon: <PiHeartLight className="shrink-0" />,
        onClick: () => setUserMenu(!userMenu),
      },
      {
        href: "#",
        text: "Sign Out",
        icon: <PiSignOutLight className="shrink-0" />,
        onClick: (e) => handleLogout(e),
      },
    ],
    signedOut: [
      {
        href: "/sign-in",
        text: "Sign In",
      },
      // {
      //   href: "/sign-up",
      //   text: "Sign Up",
      // },
    ],
  };

  const renderUserNav = () => {
    const userNav = isAuthenticated
      ? userNavData.signedIn
      : userNavData.signedOut;

    return (
      <ul className="flex flex-col gap-2.5">
        {userNav.map((item, idx) => (
          <li key={idx}>
            <NavLink
              {...(item.onClick ? { onClick: item.onClick } : {})}
              className="flex flex-row items-center gap-1.5 text-t-skin hover:text-t-beige"
              to={item.href}
            >
              {item.icon}
              {item.text}
            </NavLink>
          </li>
        ))}
      </ul>
    );
  };

  // update quotes length
  const [quotesCount, setQuotesCount] = useState(0);

  useEffect(() => {
    const updateWishlistCount = () => {
      const wishlist = loadQuoteItems() || [];
      setQuotesCount(wishlist.length);
    };

    updateWishlistCount();

    const handleWishlistChange = (event) => {
      const wishlist = loadQuoteItems();
      setQuotesCount(wishlist.length);
      localStorage.setItem("quoteItems", JSON.stringify(wishlist));
    };

    window.addEventListener("quoteCartChange", handleWishlistChange);

    return () => {
      window.removeEventListener("quoteCartChange", handleWishlistChange);
    };
  }, []);

  // state & method to re-render self on custom event dispatch
  const [, setRenderTrigger] = useState(false);
  useEffect(() => {
    const handleQuoteCartChange = () => {
      setRenderTrigger((prev) => !prev);
    };

    window.addEventListener("quoteCartChange", handleQuoteCartChange);

    return () => {
      window.removeEventListener("quoteCartChange", handleQuoteCartChange);
    };
  }, []);

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const bannerSection = (
    <div className="mx-auto flex flex-row justify-between gap-4 bg-t-black p-2 text-sm sm:p-4">
      <div className="flex flex-row gap-4 xl:gap-8">
        <a
          href={`tel:${meta.phone}`}
          className="flex flex-row items-center gap-1 text-t-skin hover:text-t-beige sm:gap-2"
        >
          <PiPhoneLight
            className="shrink-0"
            size={16}
          />
          Call
        </a>
        <a
          href={meta.chat}
          className="flex flex-row items-center gap-1 text-t-skin hover:text-t-beige sm:gap-2"
        >
          <PiChatLight
            className="shrink-0"
            size={18}
          />
          Chat
        </a>
      </div>
      <div className="flex flex-row gap-4 xl:gap-8">
        <NavLink
          to="/contact"
          className="flex flex-row items-center gap-1 text-t-skin hover:text-t-beige sm:gap-2"
        >
          <PiEnvelopeSimpleLight
            className="shrink-0"
            size={18}
          />
          <span className="max-sm:hidden">Contact</span>
        </NavLink>
        {isAuthenticated && !isCompanyUser(profile.role) && (
          <NavLink
            to="/quotes/cart"
            className="flex flex-row items-center gap-1 text-t-skin hover:text-t-beige sm:gap-2"
          >
            <div className="relative">
              <PiShoppingCartLight
                size={18}
                className="shrink-0"
              />
              <span className="absolute -right-2 -top-2 flex h-4 w-4 items-center justify-center rounded-full bg-t-skin text-t-black">
                {quotesCount}
              </span>
            </div>
            <span className="ml-1 hidden sm:block">Cart</span>
          </NavLink>
        )}
        {!isAuthenticated ? (
          <NavLink
            to="/sign-in"
            className="flex flex-row items-center gap-1 text-t-skin hover:text-t-beige sm:gap-2"
          >
            <PiUserCircleLight
              className="shrink-0"
              size={18}
            />
            Sign In
          </NavLink>
        ) : (
          <button
            type="button"
            className="flex flex-row items-center gap-1 text-t-skin hover:text-t-beige sm:gap-2"
            onClick={() => {
              setUserMenu(!userMenu);
            }}
          >
            <PiUserCircleLight
              className="shrink-0"
              size={18}
            />
            <span className="max-sm:hidden">Account</span>
          </button>
        )}
      </div>
      {isAuthenticated && userMenu && (
        <div className="absolute right-0 top-[36px] z-50 border-r border-t border-white bg-t-black px-4 py-3 sm:top-[52px]">
          {renderUserNav()}
        </div>
      )}
    </div>
  );

  const logoSection = (
    <NavLink to="/">
      <img
        className="h-12 object-contain sm:h-16 md:h-auto md:max-w-96 md:pb-6 md:pt-8"
        src={Logo}
        alt={meta.title}
      />
    </NavLink>
  );

  const buttonSection = (
    <button
      className="text-t-gray md:hidden"
      onClick={() => setOpenMainMenu(!openMainMenu)}
    >
      <PiListLight
        className="shrink-0"
        size={32}
      />
    </button>
  );

  const navLinks = (
    <ul
      className={`${openMainMenu ? "visible" : "max-md:hidden"} flex flex-col flex-wrap gap-1 p-2 max-md:bg-t-black sm:p-4 md:flex-row md:justify-center md:gap-8 md:border-y md:border-t-skin`}
    >
      {Object.keys(navbar).map((link, idx) => {
        return (
          <li key={idx}>
            <NavLink
              className={({ isActive }) =>
                `s-h4 whitespace-nowrap uppercase tracking-wider text-t-skin transition-colors duration-500 hover:text-black md:text-gray-500 ${isActive && "font-semibold"}`
              }
              onClick={() => {
                if (window.innerWidth <= 768) {
                  setOpenMainMenu(!openMainMenu);
                }
              }}
              to={navbar[link].href}
            >
              {navbar[link].text}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );

  return (
    <>
      {bannerSection}
      <div className="flex flex-row items-center justify-between gap-4 py-2 pr-2 sm:py-4 sm:pr-4 md:justify-center md:p-0">
        {logoSection}
        {buttonSection}
      </div>
      {navLinks}
    </>
  );
}
