// packages
import { useState } from "react";
import {
  PiCallBellLight,
  PiEnvelopeLight,
  PiEnvelopeSimpleLight,
  PiGiftLight,
  PiHandHeartLight,
  PiHeartFill,
  PiHeartLight,
  PiPhoneLight,
  PiShoppingCartSimpleLight,
} from "react-icons/pi";

// components (template)
import ModalInquiry from "../../components/ModalInquiry";
import ModalLogin from "../../components/ModalLogin";

// data
import { meta } from "../../data/meta";

// utils
import { toggleBookmark } from "../../shared/utils/bookmark";
import { useCompanySettings } from "../../shared/utils/company";
import { dispatchQuoteItemEvent } from "../../shared/utils/quote";
import { isClientUser } from "../../shared/utils/user";

import {
  isInQuote,
  loadQuoteItems,
  toggleQuoteItem,
} from "../../shared/utils/quote";

// TODO: this is temporary
const currentDate = new Date();
currentDate.setDate(currentDate.getDate() + 3);
const deliveryDate = currentDate.toLocaleString("en-US", {
  weekday: "short",
  month: "short",
  day: "numeric",
});

export default function ItemDetails({ dataAsParam }) {
  const [data, setData] = useState(dataAsParam);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [loginAction, setLoginAction] = useState(false);
  const [_quoteItems, setQuoteItems] = useState(loadQuoteItems());
  const [profile, setProfile] = useState({});

  const isTokenValid = () => {
    const token = localStorage.getItem("token");
    return token !== null;
  };

  const { companySettings } = useCompanySettings();
  const detailsFieldsSetting = companySettings?.find(
    (item) => item.setting_name === `${data.type}_fields`,
  );

  const detailsFields = detailsFieldsSetting
    ? detailsFieldsSetting.setting_value === "all"
      ? "all"
      : detailsFieldsSetting.setting_value
          .split(", ")
          .map((field) => field.trim())
    : "all";

  const toggleBookmarkWrapper = async (item) => {
    try {
      if (isTokenValid()) {
        item = await toggleBookmark(item);
        setData((prevData) => {
          return { ...prevData, is_bookmarked: item.is_bookmarked };
        });
      } else {
        setIsLoginModal(true);
        setLoginAction("Bookmark");
      }
    } catch (error) {
      console.error("Error toggling bookmark", error);
    }
  };

  const toggleQuoteHandler = () => {
    try {
      if (isTokenValid()) {
        const profile = JSON.parse(localStorage.getItem("profile"));
        if (isClientUser(profile.role)) {
          toggleQuoteItem(data.type, data);
          setQuoteItems(loadQuoteItems());
        } else {
          dispatchQuoteItemEvent();
        }
      } else {
        setIsLoginModal(true);
        setLoginAction("Quote");
      }
    } catch (error) {
      console.error("Error toggling quote", error);
    }
  };

  const isUserLoggedInAndNonClient = () => {
    const profile = JSON.parse(localStorage.getItem("profile"));

    if (!profile) {
      return false;
    } else if (!profile.role) {
      return false;
    } else {
      return !isClientUser(profile.role);
    }
  };

  return (
    <>
      <div className="mt-3 md:sticky md:top-4 md:mb-8">
        <div className="flex items-start justify-between">
          <div>
            <h3 className="s-h2 !font-semibold text-t-orange">{data.price}</h3>
            <h2 className="s-h2 mb-4 mt-2">{data.summary}</h2>
            {/* {data.type === "jewelry" && data.centerDescription && (
              <h4 className="mb-4 s-text">
                <b>Center Stone</b>
                <br />
                {data.centerDescription}
              </h4>
            )}
            {data.type === "jewelry" && data.sideDescription && (
              <h4 className="mb-4 s-text">
                <b>Side Details</b>
                <br />
                {data.sideDescription}
              </h4>
            )} */}
          </div>
          <button
            onClick={() => toggleBookmarkWrapper(data)}
            title={`${data.is_bookmarked ? "Remove from" : "Add to"} favorites`}
          >
            {data.is_bookmarked ? (
              <PiHeartFill
                className="shrink-0 fill-t-red hover:scale-110 hover:fill-gray-800"
                size="32"
              />
            ) : (
              <PiHeartLight
                className="shrink-0 fill-gray-800 hover:scale-110 hover:fill-t-red"
                size="32"
              />
            )}
          </button>
        </div>
        {/* shopping buttons */}
        <div className="flex gap-x-2 md:gap-x-4">
          <button
            className={`${
              isInQuote(data.type, data)
                ? "bg-t-black hover:bg-t-gray"
                : "bg-t-red hover:bg-t-gray"
            } flex w-1/2 items-start justify-center gap-2 px-3 py-3.5 font-medium text-white disabled:cursor-not-allowed disabled:bg-t-red`}
            onClick={toggleQuoteHandler}
            title={
              isUserLoggedInAndNonClient()
                ? "Disabled"
                : `${isInQuote(data.type, data) ? "Remove from" : "Add to"} quote`
            }
            disabled={isUserLoggedInAndNonClient() ? true : false}
          >
            {isUserLoggedInAndNonClient() ? (
              "Cart Disabled"
            ) : isInQuote(data.type, data) ? (
              <>
                <PiShoppingCartSimpleLight
                  className="hidden shrink-0 lg:inline-block"
                  size={20}
                />
                Remove From Cart
              </>
            ) : (
              <>
                <PiShoppingCartSimpleLight
                  className="hidden shrink-0 lg:inline-block"
                  size={20}
                />
                Add To Cart
              </>
            )}
          </button>
          <button
            className="flex w-1/2 items-center justify-center gap-2 bg-t-gray px-3 py-3.5 font-medium text-white hover:bg-t-black"
            onClick={() => setIsModalOpen(true)}
          >
            <PiCallBellLight
              className="hidden shrink-0 lg:inline-block"
              size={20}
            />
            Inquire
          </button>
        </div>
        {/* shopping details */}
        <ul className="mt-4 flex flex-row justify-between gap-1 text-sm sm:mt-8">
          <li className="flex items-center gap-1.5">
            <PiEnvelopeLight
              className="shrink-0"
              size={20}
            />
            Free Shipping
          </li>
          <li className="flex items-center gap-1.5">
            <PiHandHeartLight
              className="shrink-0"
              size={20}
            />
            Free Returns
          </li>
          <li className="flex items-center gap-1.5">
            <PiGiftLight
              className="shrink-0"
              size={20}
            />
            Delivery by {deliveryDate}
          </li>
        </ul>
        {/* sharing */}
        <ul className="mt-4 grid grid-cols-2 justify-between gap-4">
          <li>
            <a
              className="flex items-center justify-center gap-1.5 bg-t-sky py-2 font-medium text-black hover:bg-t-gray hover:text-t-skin"
              href={`tel:${meta.phone}`}
            >
              <PiPhoneLight
                className="shrink-0"
                size={16}
              />
              <span>Call</span>
            </a>
          </li>
          <li>
            <a
              className="flex items-center justify-center gap-1.5 bg-t-sky py-2 font-medium text-black hover:bg-t-gray hover:text-t-skin"
              href={`mailto:${meta.email}`}
            >
              <PiEnvelopeSimpleLight
                className="shrink-0"
                size={16}
              />
              <span>Email</span>
            </a>
          </li>
        </ul>
        {/* item details */}
        <dl className="mt-4 flex flex-col divide-y divide-black/5 sm:mt-8">
          {Object.keys(data.overview)
            .filter(
              (key) =>
                !_.isEmpty(data.overview[key]) &&
                (detailsFields === "all" || detailsFields.includes(key)),
            )
            .map((key, idx) => (
              <div
                key={idx}
                className="flex items-center justify-between pb-1 pt-2"
              >
                <dt className="s-text inline-block">{_.startCase(key)}</dt>
                <dd className="s-text inline-block">
                  {key === "gemstone" ? (
                    <div className="flex gap-1">
                      {["Natural Diamond", "Lab-Grown Diamond"].map(
                        (gemstoneOption, idx2) => (
                          <span
                            key={idx2}
                            className={`px-1 py-0 ${
                              data.overview[key] === gemstoneOption
                                ? "bg-t-gray text-white"
                                : ""
                            }`}
                          >
                            {gemstoneOption.replace(/ Diamond/, "")}
                          </span>
                        ),
                      )}
                    </div>
                  ) : (
                    data.overview[key]
                  )}
                </dd>
              </div>
            ))}
          {/* lab & certificate */}
          {data.lab && (
            <div className="flex w-full items-center justify-between pt-2">
              <dt className="s-text inline-block">
                {data.certificateUrl ? (
                  <a
                    href={data.certificateUrl}
                    target="_blank"
                  >
                    Certified by {data.lab}
                  </a>
                ) : (
                  <>Certified by {data.lab}</>
                )}
              </dt>
              {!data.certificateUrl && (
                <dd className="s-text inline-block">{data.data.lab_cert_no}</dd>
              )}
            </div>
          )}
        </dl>{" "}
      </div>
      {/* inquiry modal */}
      <ModalInquiry
        header={`Inquire About ${data.title}`}
        isModalOpen={isModalOpen}
        submitText="Submit Inquiry"
        setIsModalOpen={setIsModalOpen}
        itemType={data.type}
        itemId={data.id}
      />
      {/* login modal */}
      <ModalLogin
        header="Sign In"
        submitText="Login"
        isModalOpen={isLoginModal}
        setIsModalOpen={setIsLoginModal}
        onSuccess={
          loginAction == "Quote"
            ? toggleQuoteHandler
            : () => toggleBookmarkWrapper(data)
        }
      />
    </>
  );
}
