export function clearQuoteItems() {
  localStorage.removeItem("quoteItems");

  dispatchQuoteItemEvent();
}

export function loadQuoteItems() {
  const quoteItems = localStorage.getItem("quoteItems");
  return quoteItems ? JSON.parse(quoteItems) : [];
}

export function storeQuoteItems(quoteItems) {
  localStorage.setItem("quoteItems", JSON.stringify(quoteItems));
}

export function findQuoteItem(type, id) {
  const quoteItems = loadQuoteItems();
  return quoteItems.find((item) => item.id === id && item.type === type);
}

export function toggleQuoteItem(type, item) {
  const quoteItems = loadQuoteItems();

  if (quoteItems.length === 0) {
    quoteItems.push(item);
  } else {
    const index = quoteItems.findIndex(
      (el) => el.id === item.id && el.type === type,
    );

    if (index > -1) {
      quoteItems.splice(index, 1);
    } else {
      quoteItems.push(item);
    }
  }

  storeQuoteItems(quoteItems);
  dispatchQuoteItemEvent(quoteItems);
}

export function isInQuote(type, item) {
  return loadQuoteItems().some((el) => el.id === item.id && el.type === type);
}

export function dispatchQuoteItemEvent(quoteItems = []) {
  const event = new CustomEvent("quoteCartChange", {
    detail: { quoteItems },
  });
  window.dispatchEvent(event);
}
