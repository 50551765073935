// packages
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import {
  PiBookmarkSimple,
  PiBookmarkSimpleFill,
  PiBookmarkSimpleLight,
  PiMinusSquareLight,
  PiPlusSquareLight,
  PiShoppingCartLight,
  PiTrashLight,
  PiXSquareLight,
} from "react-icons/pi";

// assets
import "swiper/css";

// context
import { useComparison } from "../contexts/ComparisonContext";

// utils
import { isTokenValid } from "../utils/auth";
import { toggleBookmark } from "../utils/bookmark";
import { formattedCurrency } from "../utils/currency";
import {
  clearQuoteItems,
  isInQuote,
  loadQuoteItems,
  toggleQuoteItem,
} from "../utils/quote";
import { isClientUser } from "../utils/user";

const PROFILE_KEY = "profile";
const TOKEN_KEY = "token";

export default function ItemGrid({
  data,
  limit = false,
  location,
  quoteType,
  removeAllButton,
  showSummary = false,
}) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    if (!isTokenValid()) {
      localStorage.removeItem(PROFILE_KEY);
      setIsAuthenticated(false);
    } else {
      setIsAuthenticated(true);

      if (localStorage.getItem(PROFILE_KEY)) {
        setProfile(JSON.parse(localStorage.getItem(PROFILE_KEY)));
      }
    }
  }, [typeof window !== "undefined" && window.localStorage.getItem(TOKEN_KEY)]);

  //////////////////////////////////////////////////////////////////////////////
  // comparison
  //////////////////////////////////////////////////////////////////////////////

  const { comparisonData = [], toggleComparisonItem = () => {} } =
    useComparison() || {};

  const isInComparison = (item) => {
    return comparisonData.some((el) => el.id === item.id);
  };

  //////////////////////////////////////////////////////////////////////////////
  // bookmarks
  //////////////////////////////////////////////////////////////////////////////

  const toggleBookmarkWrapper = async (item, e = null) => {
    try {
      if (isTokenValid()) {
        item = await toggleBookmark(location ? item.item : item);
        setSelectedData((prevData) => {
          return prevData.map((dataItem) =>
            dataItem.id === item.id
              ? { ...dataItem, is_bookmarked: item.is_bookmarked }
              : dataItem,
          );
        });

        // TODO: fix removal of bookmark card on un-bookmark
        // if (e && location === "bookmark") {
        //   e.currentTarget.parentElement.parentElement.remove();
        // }
      } else {
        setIsLoginModal(true);
        setLoginAction("Bookmark");
      }
    } catch (error) {
      console.error("Error toggling bookmark", error);
    }
  };

  const [quoteItems, setQuoteItems] = useState([]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setQuoteItems(loadQuoteItems());
    }
  }, []);

  const handleToggleQuote = (item) => {
    if (typeof window !== "undefined") {
      toggleQuoteItem(location ? item.type : quoteType, item);
      setQuoteItems(loadQuoteItems());
    }
  };

  const handleClearQuoteItems = () => {
    clearQuoteItems();
    setQuoteItems([]);
  };

  const [selectedData, setSelectedData] = useState(
    location === "quote" ? quoteItems : limit ? data.slice(0, limit) : data,
  );

  useEffect(() => {
    setSelectedData(location === "quote" ? quoteItems : data);
  }, [quoteItems, data, setSelectedData]);

  const actionButtons = (item) => {
    return (
      <>
        {location === "bookmark" && (
          <button
            onClick={(e) => toggleBookmarkWrapper(item, e)}
            title={`${
              (item.item.type === "bookmark" && item.item.is_bookmarked) ||
              item.item.is_bookmarked
                ? "Remove from"
                : "Add to"
            } bookmarks`}
            className="p-1"
          >
            {(item.item.type === "bookmark" && item.item.is_bookmarked) ||
            item.item.is_bookmarked ? (
              <>
                <PiBookmarkSimpleFill
                  size={20}
                  className="shrink-0 text-white"
                />
              </>
            ) : (
              <>
                <PiBookmarkSimple
                  size={20}
                  className="shrink-0 text-white"
                />
              </>
            )}
          </button>
        )}
        {location === "quote" && (
          <button
            onClick={() => handleToggleQuote(item)}
            title={`${
              isInQuote(item.type, item) ? "Remove from" : "Add to"
            } quote`}
            className="p-1"
          >
            {isInQuote(item.type, item) ? (
              <PiXSquareLight
                className="text-white"
                size="22"
              />
            ) : (
              <PiShoppingCartLight
                className="text-white"
                size="22"
              />
            )}
          </button>
        )}
        {location === "compare" && (
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleComparisonItem(item);
            }}
            title={`${
              isInComparison(item) ? "Remove from" : "Add to"
            } comparison`}
            className="p-1"
          >
            {isInComparison(item) ? (
              <PiXSquareLight
                className={"shrink-0 text-white"}
                size="24"
              />
            ) : (
              <PiPlusSquareLight
                className={"shrink-0 text-white"}
                size="24"
              />
            )}
          </button>
        )}
      </>
    );
  };

  return (
    <>
      {/* Remove All Button Start */}
      {removeAllButton && (
        <div className="my-4 flex justify-end">
          {selectedData.length > 0 && (
            <button
              onClick={handleClearQuoteItems}
              className="font-playfair flex items-center justify-center bg-t-beige px-2 py-2 text-white"
              title="Clear Cart"
            >
              <PiTrashLight
                className="mr-1 shrink-0"
                size="16"
              />
              Clear Cart
            </button>
          )}
        </div>
      )}

      {/* Remove All Button Start */}
      {location && (
        <>
          {selectedData.length > 0 &&
            selectedData.some((item) =>
              location === "bookmark"
                ? item.item.type === "diamond" &&
                  item.item.is_bookmarked === true
                : item.type === "diamond",
            ) && (
              <div className="my-8">
                <div className="my-2 text-center text-lg font-medium text-t-beige">
                  Diamonds
                </div>
                <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4 lg:grid-cols-4">
                  {selectedData
                    .filter((item) =>
                      location === "bookmark"
                        ? item.item.type === "diamond" &&
                          item.item.is_bookmarked === true
                        : item.data.type === "diamond",
                    )
                    .map((item, index) => {
                      return (
                        <NavLink
                          key={index}
                          className="border-hover group relative border border-black/5 p-4"
                          to={`/diamonds/${item.id}`}
                          target="_blank"
                        >
                          <div
                            className="absolute right-0 top-0 z-20 flex flex-col items-center bg-t-beige opacity-0 transition-all duration-300 group-hover:opacity-100"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            {actionButtons(item)}
                          </div>
                          <h4 className="s-h4 mb-2 h-12 text-center text-xs">
                            {location === "bookmark"
                              ? item.item.title
                              : location === "compare"
                                ? item.title
                                : item.title}
                          </h4>
                          <div className="flex justify-center overflow-hidden py-2">
                            <img
                              alt={
                                location === "compare"
                                  ? item.images && item.images.main
                                    ? item.images.main.alt
                                    : `${item.item.description}${item.item.description_2}`
                                  : location === "quote"
                                    ? item.images
                                      ? item.images.main.alt
                                      : item.title
                                    : item.item.images
                                      ? item.item.images.main.alt
                                      : item.item.title
                              }
                              className="max-h-36 object-cover transition-all duration-300 group-hover:scale-110"
                              loading="lazy"
                              src={
                                location === "compare"
                                  ? item.images && item.images.main
                                    ? item.images.main.src
                                    : ImageIcon
                                  : location === "quote"
                                    ? item.data.images
                                      ? item.images.main.src
                                      : ImageIcon
                                    : item.item.images
                                      ? item.item.images.main.src
                                      : ImageIcon
                              }
                            />
                          </div>

                          <div
                            className={`${showSummary ? "justify-between" : "justify-center"} mt-2 flex items-center gap-2`}
                          >
                            {showSummary && (
                              <p className="font-body text-sm">
                                {location === "bookmark"
                                  ? item.item.description_2
                                  : item.summary}
                              </p>
                            )}
                            <p className="font-body font-bold">
                              {location === "bookmark"
                                ? item.item.price
                                : formattedCurrency(item.data.price_total)}
                            </p>
                          </div>
                        </NavLink>
                      );
                    })}
                </div>
              </div>
            )}
          {selectedData.length > 0 &&
            selectedData.some((item) =>
              location === "bookmark"
                ? item.item.type === "jewelry" &&
                  item.item.is_bookmarked === true
                : item.type === "jewelry",
            ) && (
              <div className="my-8">
                <div className="my-2 text-center text-lg font-medium text-t-beige">
                  Jewelry
                </div>
                <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4 lg:grid-cols-4">
                  {selectedData
                    .filter((item) =>
                      location === "bookmark"
                        ? item.item.type === "jewelry" &&
                          item.item.is_bookmarked === true
                        : item.data.type === "jewelry",
                    )
                    .map((item, index) => {
                      return (
                        <NavLink
                          key={index}
                          className="border-hover group relative border border-black/5 p-4"
                          to={`/jewelry/${item.id}`}
                          target="_blank"
                        >
                          <div
                            className="absolute right-0 top-0 z-20 flex flex-col items-center bg-t-beige opacity-0 transition-all duration-300 group-hover:opacity-100"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            {actionButtons(item)}
                          </div>
                          <h4 className="s-h4 mb-2 h-12 text-center text-xs">
                            {location === "bookmark"
                              ? item.item.title
                              : location === "compare"
                                ? `${item.data.description}${item.data.description_2}`
                                : item.title}
                          </h4>
                          <div className="flex justify-center overflow-hidden py-2">
                            <img
                              alt={
                                location === "compare"
                                  ? item.images && item.images.main
                                    ? item.images.main.alt
                                    : `${item.item.description}${item.item.description_2}`
                                  : location === "quote"
                                    ? item.images
                                      ? item.images.main.alt
                                      : item.title
                                    : item.item.images
                                      ? item.item.images.main.alt
                                      : item.item.title
                              }
                              className="max-h-36 object-cover transition-all duration-300 group-hover:scale-110"
                              loading="lazy"
                              src={
                                location === "compare"
                                  ? item.images && item.images.main
                                    ? item.images.main.src
                                    : ImageIcon
                                  : location === "quote"
                                    ? item.data.images
                                      ? item.images.main.src
                                      : ImageIcon
                                    : item.item.images
                                      ? item.item.images.main.src
                                      : ImageIcon
                              }
                            />
                          </div>
                          <div
                            className={`${showSummary ? "justify-between" : "justify-center"} mt-2 flex items-center gap-2`}
                          >
                            {showSummary && (
                              <p className="font-body text-sm">
                                {location === "bookmark"
                                  ? item.item.description_2
                                  : item.summary}
                              </p>
                            )}
                            <p className="font-body font-bold">
                              {location === "bookmark"
                                ? item.item.price
                                : formattedCurrency(item.data.price_total)}
                            </p>
                          </div>
                        </NavLink>
                      );
                    })}
                </div>
              </div>
            )}
        </>
      )}

      {/* For DiamondsUI / JewelryUI */}
      {!location && (
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4 lg:grid-cols-4">
          {selectedData &&
            selectedData.map((item, idx) => {
              return (
                <NavLink
                  key={idx}
                  className="border-hover group relative border border-black/5 p-4"
                  to={item.route}
                >
                  <div
                    className="absolute right-0 top-0 z-20 flex flex-col items-center bg-t-beige opacity-0 transition-all duration-300 group-hover:opacity-100"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleComparisonItem(item);
                      }}
                      title={`${
                        isInComparison(item) ? "Remove from" : "Add to"
                      } comparison`}
                      className="p-1"
                    >
                      {isInComparison(item) ? (
                        <PiXSquareLight
                          className={"shrink-0 text-white"}
                          size="24"
                        />
                      ) : (
                        <PiPlusSquareLight
                          className={"shrink-0 text-white"}
                          size="24"
                        />
                      )}
                    </button>
                    {isAuthenticated && (
                      <>
                        <button
                          onClick={() => toggleBookmarkWrapper(item)}
                          title={`${
                            (item.type === "bookmark" && item.is_bookmarked) ||
                            item.is_bookmarked
                              ? "Remove from"
                              : "Add to"
                          } bookmarks`}
                          className="p-1"
                        >
                          {(item.type === "bookmark" && item.is_bookmarked) ||
                          item.is_bookmarked ? (
                            <>
                              <PiBookmarkSimpleFill
                                size={24}
                                className="shrink-0 text-white"
                              />
                            </>
                          ) : (
                            <>
                              <PiBookmarkSimpleLight
                                size={24}
                                className="shrink-0 text-white"
                              />
                            </>
                          )}
                        </button>
                        {isClientUser(profile.role) && (
                          <button
                            onClick={() => handleToggleQuote(item)}
                            title={`${
                              isInQuote(quoteType, item)
                                ? "Remove from"
                                : "Add to"
                            } quote`}
                            className="p-1"
                          >
                            {isInQuote(quoteType, item) ? (
                              <PiMinusSquareLight
                                className="text-white"
                                size="22"
                              />
                            ) : (
                              <PiShoppingCartLight
                                className="text-white"
                                size="22"
                              />
                            )}
                          </button>
                        )}
                      </>
                    )}
                  </div>

                  <h4 className="s-h4 mb-2 h-12 text-center text-xs">
                    {item.title}
                  </h4>
                  {item.images && item.images.main && (
                    <div className="flex justify-center overflow-hidden py-2">
                      <img
                        alt={item.images.main.alt}
                        className="max-h-36 object-cover transition-all duration-300 group-hover:scale-110"
                        loading="lazy"
                        src={item.images.main.src}
                      />
                    </div>
                  )}
                  <div
                    className={`${showSummary ? "justify-between" : "justify-center"} mt-2 flex items-center gap-2`}
                  >
                    {showSummary && (
                      <p className="font-body text-sm">{item.summary}</p>
                    )}
                    <p className="font-body font-semibold">{item.price}</p>
                  </div>
                </NavLink>
              );
            })}
        </div>
      )}
    </>
  );
}
